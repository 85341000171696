const ABBREVIATIONS = [
    { word: 'Armed Conflict', abb: 'Conflict'},
    { word: 'Internet of Things', abb: 'IoT' },
    { word: 'Artificial Intelligence', abb: 'AI' },
    { word: 'Technology', abb: 'Tech' },
    { word: "Volleyball", abb: "Volley" },
    { word: "Marketing", abb: "Mktg" },
    { word: "Football", abb: "Footy" },
    { word: "Beauty", abb: "Beaut" },
    { word: "Finance", abb: "Fin" },
    { word: "Personal Finance", abb: "P. Fin" },
    { word: "Sport", abb: "Sports" },
    { word: "Hospitals", abb: "Hosp" },
    { word: "PS5", abb: "PS5" },
    { word: "Hardware", abb: "Hrdw" },
    { word: "Medical", abb: "Med" },
    { word: "Resorts", abb: "Resort" },
    { word: "Diet", abb: "Diet" },
    { word: "Royals", abb: "Roy" },
    { word: "Video Games", abb: "Games" },
    { word: "Authors", abb: "Auth" },
    { word: "Aviation", abb: "Aviat" },
    { word: "Climate Change", abb: "C. Change" },
    { word: "Nature", abb: "Nat" },
    { word: "Physic", abb: "Phys" },
    { word: "Reality TV", abb: "R-TV" },
    { word: "Electric Cars", abb: "EV Cars" },
    { word: "E-sport", abb: "E-Sport" },
    { word: "Women Style", abb: "W-Style" },
    { word: "Cruise", abb: "Crz" },
    { word: "International Politics", abb: "Intl. Pol" },
    { word: "PC Components", abb: "PC Comp" },
    { word: "Fitness", abb: "Fit" },
    { word: "Large Language Model", abb: "LLM" },
    { word: "Startups", abb: "Start" },
    { word: "Art & Design", abb: "Art&D" },
    { word: "Cars", abb: "Cars" },
    { word: "Law", abb: "Law" },
    { word: "Science", abb: "Sci" },
    { word: "Kitchen Products", abb: "Kit Prod" },
    { word: "Mortgage", abb: "Mortg" },
    { word: "Space", abb: "Space" },
    { word: "Software", abb: "SW" },
    { word: "Shooting", abb: "Shoot" },
    { word: "Men Style", abb: "M-Style" },
    { word: "Boxing", abb: "Box" },
    { word: "Travel Deals", abb: "T-Deals" },
    { word: "Recipes", abb: "Rec" },
    { word: "Democracy", abb: "Democ" },
    { word: "Real Estate", abb: "R. Estate" },
    { word: "Deep Learning", abb: "D. Learn" },
    { word: "Psychology", abb: "Psycho" },
    { word: "Engineering", abb: "Eng" },
    { word: "Education", abb: "Edu" },
    { word: "Netflix", abb: "Netflix" },
    { word: "Internet Provider", abb: "IP" },
    { word: "GPU", abb: "GPU" },
    { word: "Gaming", abb: "Gaming" },
    { word: "Love", abb: "Love" },
    { word: "Government", abb: "Govt" },
    { word: "Gadgets", abb: "Gadg" },
    { word: "Pets", abb: "Pets" },
    { word: "Cybersecurity", abb: "Cyber" },
    { word: "Sales", abb: "Sales" },
    { word: "Wellness", abb: "Welln" },
    { word: "Car Review", abb: "Car Rev" },
    { word: "Restaurant", abb: "Rest" },
    { word: "Cricket", abb: "Crkt" },
    { word: "Skincare", abb: "Skin" },
    { word: "Environment", abb: "Enviro" },
    { word: "Film", abb: "Film" },
    { word: "Health", abb: "Health" },
    { word: "Luxury Homes", abb: "Lux Homes" },
    { word: "Economy", abb: "Econ" },
    { word: "Russia-Ukraine War", abb: "R-U War" },
    { word: "Travel Tips", abb: "T-Tips" },
    { word: "Wine & Drinks", abb: "W&D" },
    { word: "Police", abb: "Pol" },
    { word: "Cooking", abb: "Cook" },
    { word: "Product Review", abb: "Prod Rev" },
    { word: "Movies", abb: "Movies" },
    { word: "Game Review", abb: "Game Rev" },
    { word: "Tour", abb: "Tour" },
    { word: "Mental Wellness", abb: "M. Wellness" },
    { word: "Working & Career", abb: "Work&C" },
    { word: "Interior Design", abb: "Int. Design" },
    { word: "University", abb: "Univ" },
    { word: "Tennis", abb: "Tennis" },
    { word: "Food", abb: "Food" },
    { word: "Biotech", abb: "Bio" },
    { word: "Military", abb: "Mil" },
    { word: "Nutrition", abb: "Nutri" },
    { word: "Photography", abb: "Photo" },
    { word: "Literature", abb: "Lit" },
    { word: "Martial Art", abb: "M. Art" },
    { word: "Concert", abb: "Concert" },
    { word: "Healthcare", abb: "H. Care" },
    { word: "Death", abb: "Dth" },
    { word: "Parenting", abb: "Paren" },
    { word: "Social Media", abb: "S. Media" },
    { word: "Golf", abb: "Golf" },
    { word: "Museum", abb: "Muse" },
    { word: "Innovation", abb: "Innov" },
    { word: "Soccer", abb: "Soccer" },
    { word: "Racing", abb: "Race" },
    { word: "Gunfire", abb: "Gun" },
    { word: "Tech", abb: "Tech" },
    { word: "Nintendo", abb: "Nint" },
    { word: "Legal", abb: "Leg" },
    { word: "Business", abb: "Biz" },
    { word: "XBOX", abb: "XBOX" },
    { word: "Personal Growth", abb: "P. Growth" },
    { word: "Gymnastics", abb: "Gym" },
    { word: "Tech Review", abb: "Tech Rev" },
    { word: "Crypto", abb: "Crypto" },
    { word: "Disease", abb: "Dis" },
    { word: "Court", abb: "Court" },
    { word: "Medicine", abb: "Med" },
    { word: "Stocks & Investing", abb: "Stocks&Inv" },
    { word: "Swimming", abb: "Swim" },
    { word: "LGBTQ+", abb: "LGBTQ+" },
    { word: "Travel", abb: "Travel" },
    { word: "Book Review", abb: "Book Rev" },
    { word: "Album", abb: "Album" },
    { word: "Ivy League", abb: "Ivy L." },
    { word: "Home Products", abb: "Home Prod" },
    { word: "Relationship", abb: "Rel" },
    { word: "Middle East", abb: "M. East" },
    { word: "Celebrity", abb: "Celeb" },
    { word: "Apps", abb: "Apps" },
    { word: "Landmarks", abb: "L. Marks" },
    { word: "Federal Reserve", abb: "Fed Res" },
    { word: "Wrestling", abb: "Wrest" },
    { word: "Book", abb: "Book" },
    { word: "Savings", abb: "Sav" },
    { word: "Entertainment", abb: "Ent" },
    { word: "Culture", abb: "Cult" },
    { word: "TV Show", abb: "TV Show" },
    { word: "Hollywood", abb: "Hollywd" },
    { word: "Fintech", abb: "FinTech" },
    { word: "Accounting", abb: "Acct" },
    { word: "Animal", abb: "Anim" },
];
const AbbreviationData={ABBREVIATIONS};
export default AbbreviationData;