import React from "react";

import {
    Autocomplete,
    IconButton,
    Stack,
    TextField
} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';

import * as Constants from '../../config/Constants';
import LogoData from '../../Data/LogoData';
import TagsData from '../../Data/TagsData';
import * as Utils from '../utils/Utils';

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiFilledInput-root': {
        color: '#F7F7F8',
    },
});

export default function SearchToolbar(props) {
    const handleSearch = () => {
        props.handleSearch();
    };

    // Combine LogoData & TagsData
    let logos = LogoData.LOGOS
    const logo_brands = Utils.getLogoDisplayArray(LogoData.LOGOS)
    const logo_arr = logo_brands.map((option) => {
        return {
            topic: Constants.SEARCH_TOOLBAR_TOPIC_SOURCE,
            name: option,
        };
    });

    const tag_arr = TagsData.TAGS.map((option) => {
        return {
            topic: Constants.SEARCH_TOOLBAR_TOPIC_TAG,
            name: option,
        };
    });
    const options = logo_arr.concat(tag_arr);

    const [keywords, setKeywords] = React.useState('');

    return (
        <div>
            <Autocomplete
                sx={{
                    width: Constants.SEARCH_TOOLBAR_HEADER_WIDTH,
                }}
                size="small"
                freeSolo
                options={options}
                groupBy={(option) => option.topic}
                getOptionLabel={(option) => option.name || keywords}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) =>
                    <Stack direction="row">
                        <CssTextField {...params} 
                            label="Search"
                            variant="filled"
                            size="small"
                            color="warning"
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            }}
                        />
                        <IconButton
                            type="button"
                            color="inherit"
                            sx={{ ml: 1 }}
                            aria-label="search"
                            onClick={ (e) => {
                                props.setIncludeSources([]);
                                props.setTags([]);
                                props.setSearchKeywords(keywords);
                                handleSearch();
                            }}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Stack>
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        { (option.topic === Constants.SEARCH_TOOLBAR_TOPIC_SOURCE) ? 
                            <NewspaperIcon style={{ marginRight: 16 }} color='primary' /> :
                            <LocalOfferIcon style={{ marginRight: 16 }} color='primary' />
                        }
                        { option.name }
                    </li>
                )}
                onInputChange={(e, value) => {
                    setKeywords(value);
                }}
                onChange={(event, value) => {
                    props.setIncludeSources(
                        (value !== null && value.topic === Constants.SEARCH_TOOLBAR_TOPIC_SOURCE) ?
                        [Utils.getBrandFromDisplayName(value.name, logos)] : []);
                    props.setTags(
                        (value !== null && value.topic === Constants.SEARCH_TOOLBAR_TOPIC_TAG) ?
                        [value.name] : []);
                    props.setSearchKeywords('');
                    handleSearch();
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        props.setIncludeSources([]);
                        props.setTags([]);
                        props.setSearchKeywords(keywords);
                        handleSearch();
                    }
                }}
            />
        </div>
    );
}
