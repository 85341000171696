import React, { useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import * as Constants from '../../config/Constants';
import SearchCheckBox from "./SearchCheckBox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function SourceSelectionDialog(props) {
    const handleClose = () => {
        props.setOpen(false);
    };

    const handleCloseNeedUpdate = () => {
        localStorage.setItem('excludeSources',
                             JSON.stringify(excludeSources));
        props.setLoadingCategory(true);
        props.setReloadLocalStorage(true);
        handleClose();
    };

    const [excludeSources, setExcludeSources] = useState(
        JSON.parse(localStorage.getItem('excludeSources'))
    );
    // if not exist yet
    if (excludeSources === null)
        setExcludeSources([]);

    return (
        <BootstrapDialog onClose={ handleClose } open={ props.open }>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                { Constants.SSDIALOG_TITLE }
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={ handleClose }
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom sx={{ pb: 2 }}>
                    { Constants.SSDIALOG_CONTENT }
                </Typography>
                <SearchCheckBox
                    excludeSources={ excludeSources }
                    setExcludeSources={ setExcludeSources }
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={ handleCloseNeedUpdate }>
                    { Constants.BUTTON_SAVE_CHANGE }
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}