const TAGS=[
	'Stocks & Investing',
	'Crypto',
	'Personal Finance',
	'Real Estate',
	'Working & Career',
	'Business',
	'Productivity',
	'Travel Tips',
	'Luxury Homes',
	'Architecture',
	'Interior Design',
	'Women Style',
	'Men Style',
	'Wine & Drinks',
	'Home Products',
	'Kitchen Products',
	'Exercise',
	'Mental Welness',
	'Parenting',
	'COVID',
	'Disease',
	'Cancer',
	'Nutrition',
	'Self-awareness',
	'Startups',
	'Electric Cars',
	'Apps',
	'Gadgets',
	'Nature',
	'Climate Change',
	'Space',
	'Gaming',
	'Artificial Intelligence',
	'Book',
	'Art',
	'Culture',
	'Royals',
	'Reality TV',
	'TV & Movie',
	'Celebrity',
	'Restaurant',
	'Recipes',
	'Basketball',
	'Football',
	'Soccer',
	'Racing',
	'Golf',
	'Tennis',
	'Boxing',
	'Baseball',
	'Gymnastics',
	'Volleyball',
	'Swimming',
	'Skiing',
	'Cricket',
	'Hockey',
	'Wrestling',
	'Martial art',
	'U.S. Politics',
	'Russia-Ukraine War',
	'International Politics',
	'Aviation',
	'Election 2024',
];
const TagsData={TAGS};
export default TagsData;
