import * as React from 'react';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    XIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import {
    Backdrop,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

import * as Constants from '../../config/Constants';

export default function ShareButton(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const hareOpen = Boolean(anchorEl);
    const handleShareClose = () => {
        setAnchorEl(null);
    };
    let open = (anchorEl !== null);

    return (
        <div>
            <IconButton
                onClick={ (e) => {
                    setAnchorEl(e.currentTarget);
                }}
                sx={{
                    boxShadow: Constants.SENTIMENT_ICON_SHADOW,
                    m: 1,
                }}
                size='small'
            >
                <ShareIcon color='primary' />
            </IconButton>
            <Backdrop
                open={open}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1  }}
            >
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={ anchorEl }
                    open={ hareOpen }
                    onClose={ handleShareClose }
                >
                    <MenuItem
                        key={ 'email' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <EmailShareButton
                            url={ props.news.url }
                            subject={ Constants.CARD_SHARE_TEXT }
                            // body=''
                        >
                            <EmailIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </EmailShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'facebook' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <FacebookShareButton
                            url={ props.news.ur }
                            quote={ Constants.CARD_SHARE_TEXT }
                        >
                            <FacebookIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </FacebookShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'facebook_messenger' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <FacebookMessengerShareButton
                            url={ props.news.ur }
                        >
                            <FacebookMessengerIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </FacebookMessengerShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'linkedin' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <LinkedinShareButton
                            url={ props.news.ur }
                            title={ Constants.CARD_SHARE_TEXT }
                            // summary=""
                            // source=""
                        >
                            <LinkedinIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </LinkedinShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'pinterest' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <PinterestShareButton
                            url={ props.news.ur }
                            description={ Constants.CARD_SHARE_TEXT }
                        >
                            <PinterestIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </PinterestShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'reddit' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <RedditShareButton
                            url={ props.news.ur }
                            title={ Constants.CARD_SHARE_TEXT }
                        >
                            <RedditIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </RedditShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'telegram' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <TelegramShareButton
                            url={ props.news.ur }
                            title={ Constants.CARD_SHARE_TEXT }
                        >
                            <TelegramIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </TelegramShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'twitter' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <TwitterShareButton
                            url={ props.news.ur }
                            title={ Constants.CARD_SHARE_TEXT }
                            // hashtags={[]}
                        >
                            <XIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </TwitterShareButton>
                    </MenuItem>
                    <MenuItem
                        key={ 'whatsapp' }
                        onClick={ handleShareClose }
                        sx={{ p: 0 }}
                    >
                        <WhatsappShareButton
                            url={ props.news.ur }
                            title={ Constants.CARD_SHARE_TEXT }
                        >
                            <WhatsappIcon
                                size={ Constants.CARD_SHARE_ICON_SIZE }
                                round
                                style={{ padding: Constants.CARD_SHARE_ICON_PAD }}
                            />
                        </WhatsappShareButton>
                    </MenuItem>
                </Menu>
            </Backdrop>
        </div>
    );
}
