import * as Constants from '../../config/Constants';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

export const getTimeAgo = (date) => {
    const secondsAgo = Math.max(0, Math.round((Date.now() - Number(date)) / 1000));

    if (secondsAgo < MINUTE) {
        return "Just now";
    }

    let divisor;
    let unit = "";

    if (secondsAgo < HOUR) {
        [divisor, unit] = [MINUTE, "minute"];
    } else if (secondsAgo < DAY) {
        [divisor, unit] = [HOUR, "hour"];
    } else if (secondsAgo < WEEK) {
        [divisor, unit] = [DAY, "day"];
    } else if (secondsAgo < MONTH) {
        [divisor, unit] = [WEEK, "week"];
    } else if (secondsAgo < YEAR) {
        [divisor, unit] = [MONTH, "month"];
    } else {
        [divisor, unit] = [YEAR, "year"];
    }

    const count = Math.floor(secondsAgo / divisor);
    return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
};

export const getTags = (input) => {
    if (input === undefined) return [[], false];

    var tags = input.split(Constants.TAG_SEPARATOR);
    if (tags.length > Constants.MAX_NUM_TAGS_ALLOWED) {
        tags = tags.slice(0, Constants.MAX_NUM_TAGS_ALLOWED)
    }
    var haveTag = (tags.length > 0 && tags[0].length > 0);
    return [tags, haveTag];
};

export const cutLongText = (text, maxLength) => {
    if (text.length > maxLength) {
        text = text.slice(0, maxLength) + '...';
    }
    return text;
};

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const getLogoDisplayArray = (logos) => {
    return logos.map((item, index) => item.display);
}

export const getLogoBrandArray = (logos) => {
    return logos.map((item, index) => item.brand);
}

export const getBrandFromDisplayName = (display, logoData) => {
    for (var i = 0; i < logoData.length; i++) {
        if (logoData[i].display === display)
            return logoData[i].brand;
    }
    return null;
}

export const array_to_string = (arr) => {
    return arr.join(';');
}

export const get_domain_from_url = (url) => {
    var p_url = new URL(url);
    return p_url.hostname;
}

export const validateName = (name) => {
    // Define the regular expression pattern for a valid name
    const namePattern = /^[a-zA-Z\s-]+$/;

    //test whether input is valid
    return (namePattern.test(name));
};

export const validateEmail = (email) => {
    // Define the regular expression pattern for a valid email address
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //test whether input is valid
    return (emailPattern.test(email));
};