import React, { useEffect, useState } from "react";

import {
    Tab,
    Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import * as Constants from '../../config/Constants';
import CategoriesData from "../../Data/CategoriesData";

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
    ))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: Constants.CATEGORY_BAR_INDICATOR_WIDTH,
        width: '100%',
        backgroundColor: '#dc143c',
    },
});
  
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'capitalize',
        color: Constants.CATEGORY_BAR_COLOR,
        '&.Mui-selected': {
            color: Constants.CATEGORY_BAR_SELECTED_COLOR,
            fontSize: theme.typography.pxToRem(Constants.CATEGORY_BAR_SELECTED_FONT_SIZE),
        },
        '&.Mui-focusVisible': {
            backgroundColor: Constants.CATEGORY_BAR_HOVER_COLOR,
            fontSize: theme.typography.pxToRem(Constants.CATEGORY_BAR_SELECTED_FONT_SIZE),
        },
    }),
);

export default function CategoryBar(props) {
    const [excludeCategories, setExcludeCategories] = useState(
        JSON.parse(localStorage.getItem('excludeCategories'))
    );
    if (excludeCategories === null)
        setExcludeCategories([]);

    let categories = CategoriesData.CATEGORIES;
    const [finalCategories, setFinalCategories] = useState(categories);

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);

        // update parent component
        props.setSelectedCategory(finalCategories[newValue]);
        props.setLoadingCategory(true);
    };

    if (props.reloadCategory) {
        props.setReloadCategory(false);
        setExcludeCategories(
            JSON.parse(localStorage.getItem('excludeCategories'))
        );
    }

    useEffect(() => {
        if (excludeCategories !== null) {
            setFinalCategories(categories.filter(
                (category) => !excludeCategories.includes(category)
            ))
        } else {
            setFinalCategories(categories);
        }
    }, [categories, excludeCategories]);

    return (
        <div style={{
            display: 'flex',
            gap: '1.5rem',
            justifyContent: 'center',
            margin: '0rem auto',
            width: '99%'
        }}>
            <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
            >
                {finalCategories.map((item, index) => (
                    <StyledTab
                        key={ index }
                        label={ item }
                        sx={{
                            px: 1,
                            fontWeight: 600,
                            minWidth: 0,
                            fontSize: Constants.CATEGORY_BAR_FONT_SIZE
                        }}
                    />
                ))}
            </StyledTabs>
        </div>
    );
}
