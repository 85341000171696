import * as React from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
}from '@mui/material';

import MultiLineText from './MultiLineText';
import tanda from "../../Data/TermsAndAgreements.txt"

export default function TandADialog(props) {
    const [terms, setTerms] = React.useState('');

    fetch(tanda)
    .then(r => r.text())
    .then(text => {
        setTerms(text);
    });

    const handleClose = () => {
        props.setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (props) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props]);

    return (
        <div>
            <Dialog
                open={ props.open }
                onClose={ handleClose }
                scroll={ 'paper' }
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    Terms of Service
                </DialogTitle>
                <DialogContent dividers={ true }>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={ descriptionElementRef }
                        tabIndex={ -1 }
                    >
                        <MultiLineText text={ terms }/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose }>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
