import { useState } from "react";


// @mui material components
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

// EmailJS
import emailjs from '@emailjs/browser';

// Shared config & utils
import * as Constants from "../../config/Constants"
import * as Utils from "../utils/Utils";

function ContactUsDialog({open, setOpen}) {
  const [sending, setSending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validMessage, setValidMessage] = useState(false);

  const handleClose = () => {
    setSending(false);
    setOpen(false);
    setErrorMsg('');
  };

  const handleNameValidation = (e) => {
    //test whether TextField is valid
    setValidName(Utils.validateName(e.target.value));
  };

  const handleEmailValidation = (e) => {
    //test whether TextField is valid
    setValidEmail(Utils.validateEmail(e.target.value));
  };

  const handleMessageValidation = (e) => {
    //test whether TextField is valid
    setValidMessage(e.target.value.length > 0);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          setSending(true);

          if (!validEmail || !validMessage || !validName) {
            return;
          }

          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          emailjs
            .send(Constants.EMAILJS_SERVICE_ID, Constants.EMAILJS_TEMPLATE_ID, formJson, {
              publicKey: Constants.EMAILJS_PUBLIC_KEY,
            })
            .then(
              () => handleClose(),
              (error) => setErrorMsg('There was issue sending your email. Please try again later.'),
            );
        },
      }}
    >
      <DialogTitle>Contact us</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {errorMsg}
        </DialogContentText>
        <DialogContentText pt={2} pb={1}>
          <TextField
            autoFocus
            required
            name="email"
            type="from_email" 
            label="Your email"
            fullWidth
            onChange={(e) => handleEmailValidation(e)}
            error={!validEmail}
          />
        </DialogContentText>
        <DialogContentText py={1}>
          <TextField
            required
            name="from_name"
            label="Your Name"
            fullWidth
            onChange={(e) => handleNameValidation(e)}
            error={!validName}
          />
        </DialogContentText>
        <DialogContentText pt={1} pb={2}>
          <TextField
            required
            name="message"
            label="Message"
            multiline rows={5}
            fullWidth
            onChange={(e) => handleMessageValidation(e)}
            error={!validMessage}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={sending || !validEmail || !validMessage || !validName}
          type="submit"
          sx={{ minWidth: '300px', pb: '10px' }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactUsDialog;