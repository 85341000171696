import React from "react";

import {
    Autocomplete,
    // Avatar,
    // Box,
    Checkbox,
    TextField
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// import * as Constants from '../../config/Constants';
import LogoData from '../../Data/LogoData';
import * as Utils from '../utils/Utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchCheckBox(props) {
    let logoData = LogoData.LOGOS;

    const getLogoDataItemsFromChecked = (sources) => {
        var logos = [];
        if (sources !== null && sources.length > 0)
            for (var i = 0; i < logoData.length; i++) {
                if (sources.indexOf(logoData[i].brand) >= 0)
                    logos.push(logoData[i]);
            }
        return logos;
    };

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={ logoData }
            disableCloseOnSelect
            defaultValue={ getLogoDataItemsFromChecked(props.excludeSources) }
            getOptionLabel={(option) => option.display}
            sx={{
                maxWidth: 360,
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}> */}
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        { option.display }
                        {/* <Avatar
                            alt={ option.url }
                            src={ option.logo }
                            sx={{
                                width: 30,
                                height: 30
                            }}
                        /> */}
                    {/* </Box> */}
                </li>
            )}
            style={{ 
                flexGrow: 1,
                p: 2 
            }}
            renderInput={(params) => (
                <TextField {...params} label="Sources" placeholder="More" />
            )}
            onChange={(event, value) => {
                props.setExcludeSources(Utils.getLogoBrandArray(value));
			}}
        />
    );
}
