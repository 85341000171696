import React, { useState, useRef } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import {
    Box,
    CssBaseline,
    Slide,
    Snackbar,
    Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//components
import './app.css';
import HeaderBar from './components/Header/Header';
import Footer from './components/Footer/Footer';
import News from "./components/News/News";
import ManageCategoriesDialog from "./components/UI/ManageCategoriesDialog";
import SourceManagementDialog from "./components/UI/SourceManagementDialog";

import CategoriesData from "./Data/CategoriesData";
import * as Constants from './config/Constants';
import PrivacyDialog from "./components/UI/PrivacyPolicy";
import TandADialog from './components/UI/TandA';

// Define theme settings
const light = {
    palette: {
        mode: "light",
    },
};
  
const dark = {
    palette: {
        mode: "dark",
    },
};

const App=()=> {
    const topSection = useRef(null);
    let categories = CategoriesData.CATEGORIES;

    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [selectedCategory, setSelectedCategory] = React.useState(categories[0]);
    const [loadingCategory, setLoadingCategory] = React.useState(true);
    const [reloadLocalStorage, setReloadLocalStorage] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [openSideBar, setOpenSideBar] = React.useState(false);
    const [openSource, setOpenSource] = React.useState(false);
    const [openCategory, setOpenCategory] = React.useState(false);
    const [includeSources, setIncludeSources] = React.useState([]);
    const [tags, setTags] = React.useState([]);
    const [searchKeywords, setSearchKeywords] = React.useState('');
    const [openTerms, setOpenTerms] = React.useState(false);
    const [openPolicy, setOpenPolicy] = React.useState(false);

    // This function is triggered when the Switch component is toggled
    const changeTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };

    const showAlertMessage = (msg) => {
        setOpenAlert(true);
        setAlertMsg(msg);
    };

    const location = useLocation();

    return (
        <ThemeProvider
            theme={isDarkTheme ? createTheme(dark) : createTheme(light)}
        >
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: Constants.OUTERBOX_MIN_HEIGHT,
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[200]
                            : theme.palette.grey[800],
                        py: Constants.FOOTER_PAD,
                }}
            >
                <HeaderBar
                    category={ selectedCategory }
                    setSelectedCategory={ setSelectedCategory }
                    openSideBar={ openSideBar }
                    setOpenSideBar={ setOpenSideBar }
                    // openSource={ openSource }
                    setOpenSource={ setOpenSource }
                    // openCategory={ openCategory }
                    setOpenCategory={ setOpenCategory }
                    searchKeywords={ searchKeywords }
                    setSearchKeywords={ setSearchKeywords }
                    setLoadingCategory={ setLoadingCategory }
                    showAlertMessage={ showAlertMessage }
                    setIncludeSources={ setIncludeSources }
                    setTags={ setTags }
                    isDarkTheme={ isDarkTheme }
                    changeTheme={ changeTheme }
                    setOpenTerms={setOpenTerms}
                    setOpenPolicy={setOpenPolicy}
                />
                <Toolbar sx={{ p: 0 }} ref={ topSection }/>
                <Snackbar
                    anchorOrigin={{ 
                        vertical: Constants.SNACKBAR_VERTICAL,
                        horizontal: Constants.SNACKBAR_HORIZONTAL
                    }}
                    open={ openAlert }
                    autoHideDuration={ Constants.SNACKBAR_DURATION }
                    onClose={() => {
                        setOpenAlert(false);
                    }}
                    message={ alertMsg }
                    TransitionComponent={Slide}
                />
                <SourceManagementDialog
                    open={ openSource }
                    setOpen={ setOpenSource }
                    setLoadingCategory={ setLoadingCategory }
                    setReloadLocalStorage={ setReloadLocalStorage }
                />
                <ManageCategoriesDialog
                    open={ openCategory }
                    setOpen={ setOpenCategory }
                />
                <div>
                    <div className="app-content">
                        <TransitionGroup>
                            <CSSTransition 
                                timeout={300} 
                                classNames='fade' 
                                key={location.key}
                            >
                                <Switch location={location}>
                                    <Route path="/" exact>
                                        <Redirect to="/news" />
                                    </Route>

                                    <Route path="/news">
                                        <News
                                            loadingCategory={ loadingCategory }
                                            setLoadingCategory={ setLoadingCategory }
                                            selectedCategory={ selectedCategory }
                                            setSelectedCategory={ setSelectedCategory }
                                            topSection={ topSection }
                                            openSource={ openSource }
                                            reloadLocalStorage={ reloadLocalStorage }
                                            setReloadLocalStorage={ setReloadLocalStorage }
                                            searchKeywords={ searchKeywords }
                                            includeSources={ includeSources }
                                            tags={ tags }
                                        />
                                    </Route>

                                    <Route path="*">
                                        <Redirect to="/news" />
                                    </Route>

                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
            </Box>
            <Footer setOpenTerms={setOpenTerms} setOpenPolicy={setOpenPolicy}/>
            <TandADialog 
                open={ openTerms }
                setOpen={ setOpenTerms }
            />
            <PrivacyDialog 
                open={ openPolicy }
                setOpen={ setOpenPolicy }
            />
        </ThemeProvider>
    )
}

export default App;