import React, { useState, useEffect } from "react";
import Moment from "moment";
import PropTypes from 'prop-types';

import {
    Backdrop,
    Box,
    CircularProgress,
    Fab,
    Fade,
    Toolbar,
    useScrollTrigger,
} from '@mui/material';
import styles from "./news.module.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import NewsItem from "./NewsItem";
import * as Constants from '../../config/Constants';
import * as Utils from '../utils/Utils';

const scrollDown = (ref) => {
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
    });
};

function ScrollTop(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });
  
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );
    
        if (anchor) {
            anchor.scrollIntoView({
            block: 'center',
            });
        }
    };
  
    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}
  
ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function checkIfNeedRefetchAPI() {
    let duration = Moment.duration(Moment().diff(lastCallAPITime));
    let diff_hours = duration.asHours();
    if (diff_hours > Constants.API_KEEP_OFFLINE_TIME) {
        // Need to refetch, so clear the cache
        resetListNewsMap();
    }
};

function resetListNewsMap() {
    listNewsMap = new Map();
    lastCallAPITime = Moment();
}


let lastCallAPITime = Moment()
let listNewsMap = new Map(); // for caching API call results

const News = (props) => {
    const [spinning, setSpinning] = useState(false);
    const [listNews, setListNews] = useState([]);

    const [excludeSources, setExcludeSources] = useState(
        JSON.parse(localStorage.getItem('excludeSources'))
    );
    // if not exist yet
    if (excludeSources === null)
        setExcludeSources([]);

    useEffect(() => {
        if (!props.loadingCategory) {
            return;
        };

        if (props.reloadLocalStorage) {
            props.setReloadLocalStorage(false);
            setExcludeSources(
                JSON.parse(localStorage.getItem('excludeSources')
            ));
            return;
        }

        setSpinning(true);
        setListNews([]);
        let api_cmd = ''
        if (props.selectedCategory === Constants.TOP_STORY_CATEGORY_NAME) {
            api_cmd = 'get_top_stories?min_score='
                    + Constants.TOP_STORY_MIN_SCORE
        } else {
            api_cmd = 'get_latest_news_by_category?category='
                    + props.selectedCategory
        }
        api_cmd += '&search='
                +  props.searchKeywords
                +  '&tags='
                +  Utils.array_to_string(props.tags)
                +  '&include_sources='
                +  Utils.array_to_string(props.includeSources)
                +  '&exclude_sources='
                +  Utils.array_to_string(excludeSources)

        const fetchData = async () => {
            fetch(
                Constants.API_IP_ADDRESS + api_cmd
            )
            .then((response) => response.json())
            .then((data) => {
                handleDataFetched(data);
                listNewsMap.set(api_cmd, data);
            })
            .catch((err) => {
                console.log(err.message);
            });
        };

        const handleDataFetched = (data) => {
            setListNews(data);
            props.setLoadingCategory(false);
            setSpinning(false);
            scrollDown(props.topSection);
        };

        checkIfNeedRefetchAPI();

        if (listNewsMap.has(api_cmd)) {
            handleDataFetched(listNewsMap.get(api_cmd));
        } else {
            fetchData();
        }

        return;
    }, [ props, excludeSources ]);

    return (
        <React.Fragment>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={spinning}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Toolbar id="back-to-top-anchor" />
            <div className={ styles.projectList }>
                { listNews.map((item, index) => {
                    return (
                        <NewsItem key={index} news={item} />
                    )
                })}
            </div>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon color='primary' />
                </Fab>
            </ScrollTop>
        </React.Fragment>
    )
};

export default News;