import React, { useState } from "react";
import PropTypes from 'prop-types';

import {
    AppBar,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Slide,
    Toolbar,
    Typography,
    useScrollTrigger,
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Newspaper from "@mui/icons-material/Newspaper";
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SendIcon from '@mui/icons-material/Send';
import PolicyIcon from '@mui/icons-material/Policy';

import CategoryBar from "../UI/CategoryBar";
import ContactUsDialog from "../UI/ContactUsDialog";
import SearchToolbar from "../UI/SearchToolbar";
import SubscribeDialog from '../UI/Subscribe'

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
  
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
  }
  
HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
};
  

function HeaderBar(props) {
    const [openContactUs, setOpenContactUs] = useState(false);

    const handleSearch = () => {
        props.setLoadingCategory(true);
    };

    // handle menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // handle subscribe
    const [openSubscribe, setOpenSubscribe] = React.useState(false);
    const handleSubscibeOpen = () => {
        setOpenSubscribe(true);
    };
    const handleSubscibeClose = () => {
        setOpenSubscribe(false);
    };

    let isDark = props.isDarkTheme

    return (
    <HideOnScroll {...props}>
            <AppBar>
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            p: 0,
                        }}
                    >
                        R4U
                    </Typography>
                    <SearchToolbar
                        handleSearch={ handleSearch }
                        searchKeywords={ props.searchKeywords }
                        setSearchKeywords={ props.setSearchKeywords }
                        setIncludeSources={ props.setIncludeSources }
                        setTags={ props.setTags }
                    />
                    <IconButton
                        color="inherit"
                        aria-label="directions"
                        onClick={ (e) => {
                            handleMenuOpen(e);
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleMenuClose}
                        onClick={handleMenuClose}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem 
                            onClick={ () => {
                                handleMenuClose();
                                props.setOpenCategory(true);
                            }}
                        >
                            <ListItemIcon>
                                <CategoryIcon fontSize="small" />
                            </ListItemIcon>
                            Manage Categories
                        </MenuItem>
                        <MenuItem 
                            onClick={ () => {
                                handleMenuClose();
                                props.setOpenSource(true);
                            }}
                        >
                            <ListItemIcon>
                                <Newspaper fontSize="small" />
                            </ListItemIcon>
                            Manage Sources
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={ () => {
                                props.changeTheme();
                                handleMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                { isDark 
                                    ? <LightModeIcon /> 
                                    : <DarkModeIcon /> 
                                }
                            </ListItemIcon>
                            <ListItemText>
                                { (isDark ? 'Light Mode' : 'Dark Mode') }
                            </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem 
                            onClick={ () => {
                                handleMenuClose();
                                handleSubscibeOpen();
                            }}
                        >
                            <ListItemIcon>
                                <SubscriptionsIcon fontSize="small" />
                            </ListItemIcon>
                            Subscribe
                        </MenuItem>
                        <MenuItem 
                            onClick={ () => {
                                setOpenContactUs(true);
                                handleMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                <SendIcon fontSize="small" />
                            </ListItemIcon>
                            Contact Us
                        </MenuItem>
                        <Divider />
                        <MenuItem 
                            onClick={ () => props.setOpenPolicy(true) }
                        >
                            <ListItemIcon>
                                <PolicyIcon fontSize="small" />
                            </ListItemIcon>
                            Privacy Policy
                        </MenuItem>
                        <MenuItem 
                            onClick={ () => props.setOpenTerms(true) }
                        >
                            <ListItemIcon>
                                <HandshakeIcon fontSize="small" />
                            </ListItemIcon>
                            Terms of Use
                        </MenuItem>
                    </Menu>
                    <SubscribeDialog 
                        open={ openSubscribe }
                        handleClose={ handleSubscibeClose }
                        showAlertMessage={ props.showAlertMessage }
                    />
                </Toolbar>
                <CategoryBar
                    category={ props.selectedCategory }
                    setSelectedCategory={ props.setSelectedCategory }
                    setLoadingCategory={ props.setLoadingCategory }
                    reloadCategory={ props.reloadCategory }
                    setReloadCategory={ props.setReloadCategory }
                />
                <ContactUsDialog open={openContactUs} setOpen={setOpenContactUs} />
            </AppBar>
        </HideOnScroll>
    );
}
export default HeaderBar;