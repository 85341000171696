import React, { useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import CategoriesData from "../../Data/CategoriesData";
import * as Constants from '../../config/Constants';
import SimpleSearchCheckBox from "./SimpleSearchCheckBox";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ManageCategoriesDialog(props) {
    const [excludeCategories, setExcludeCategories] = useState(
        JSON.parse(localStorage.getItem('excludeCategories'))
    );
    if (excludeCategories === null)
        setExcludeCategories([]);

    let categories = CategoriesData.CATEGORIES;

    const handleClose = () => {
        props.setOpen(false);
        setExcludeCategories(
            JSON.parse(localStorage.getItem('excludeCategories'))
        );
    };

    const handleCloseNeedUpdate = () => {
        localStorage.setItem('excludeCategories',
                             JSON.stringify(excludeCategories));
        handleClose();
        window.location.reload(true);
    };

    return (
        <BootstrapDialog onClose={ handleClose } open={ props.open }>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                { Constants.MCDIALOG_TITLE }
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={ handleClose }
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom sx={{ pb: 2 }}>
                    { Constants.MCDIALOG_CONTENT }
                </Typography>
                <SimpleSearchCheckBox
                    data={ categories }
                    value={ excludeCategories }
                    setValue={ setExcludeCategories }
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={ handleCloseNeedUpdate }>
                    { Constants.BUTTON_SAVE_CHANGE }
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}