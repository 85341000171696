import * as React from "react";

import {
    Box,
    Link,
    Stack,
    Typography
} from "@mui/material";

import * as Constants from '../../config/Constants';

export default function Footer(props) {
    const handleClickTerms = () => {
        props.setOpenTerms(true);
    };

    const handleClickPrivacy = () => {
        props.setOpenPolicy(true);
    };

    return (
        <Box
            component="footer"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                    py: Constants.FOOTER_PAD,
                }}
        >
            <Stack
                direction="row"
                spacing={ Constants.FOOTER_STACK_SPACING }
            >
                <Typography variant="body1" color="text.secondary">
                    {"© "}
                    {new Date().getFullYear()}
                    {" "}
                    <Link color="inherit" href="https://www.speedai.co" target="_">
                        Speed AI
                    </Link>
                    {", LLC."}
                </Typography>
                <Link
                    component="button"
                    variant="body1"
                    color="text.secondary"
                    onClick={ handleClickTerms }
                >
                    Terms
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    color="text.secondary"
                    onClick={ handleClickPrivacy }
                >
                    Privacy
                </Link>
                <Link
                    component="button"
                    variant="body1"
                    color="text.secondary"
                >
                    <a href="mailto:info@speedai.co">Contact us</a>
                </Link>
            </Stack>
        </Box>
    );
}