const LOGOS=[
{
	brand: '247wallst',
	url: ['https://247wallst.com', ],
	logo: 'https://247wallst.com/favicon.ico',
	display: '24/7 Wall St.',
},
{
	brand: '9to5google',
	url: ['https://9to5google.com', ],
	logo: 'https://9to5google.com/wp-content/uploads/sites/4/2017/03/favicon-bg-none-face-white.png?w=192',
	display: '9To5Google',
},
{
	brand: '9to5toys',
	url: ['https://9to5toys.com', ],
	logo: 'https://9to5toys.com/wp-content/uploads/sites/5/2017/03/toys1.png?w=192',
	display: '9To5Toys',
},
{
	brand: 'aeon',
	url: ['https://aeon.co', ],
	logo: 'https://aeon.co/apple-touch-icon.png',
	display: 'Aeon',
},
{
	brand: 'afar',
	url: ['https://www.afar.com', ],
	logo: 'https://www.afar.com/apple-touch-icon.png',
	display: 'AFAR',
},
{
	brand: 'afp',
	url: ['https://www.afp.com', ],
	logo: 'https://www.afp.com/favicon.ico',
	display: 'AFQ',
},
{
	brand: 'aibusiness',
	url: ['https://aibusiness.com', ],
	logo: '',
	display: 'aibusiness',
},
{
	brand: 'allure',
	url: ['https://www.allure.com', ],
	logo: 'https://www.allure.com/apple-touch-icon.png',
	display: 'Allure',
},
{
	brand: 'analyticsinsight',
	url: ['https://www.analyticsinsight.net', ],
	logo: 'https://www.analyticsinsight.net/wp-content/uploads/2022/11/cropped-logo-300x40-1-192x192.png',
	display: 'Analytics Insight',
},
{
	brand: 'animenewsnetwork',
	url: ['https://www.animenewsnetwork.com', ],
	logo: 'https://www.animenewsnetwork.com/apple-touch-icon.png',
	display: 'Anime News Network',
},
{
	brand: 'aol',
	url: ['https://www.aol.com', ],
	logo: 'https://s.yimg.com/cv/apiv2/aolfp/images/favicon/apple-touch-icon-180x180.png',
	display: 'Aol.',
},
{
	brand: 'apnews',
	url: ['https://apnews.com', ],
	logo: 'https://apnews.com/apple-touch-icon.png',
	display: 'AP News',
},
{
	brand: 'appleinsider',
	url: ['https://appleinsider.com', ],
	logo: 'https://photos5.appleinsider.com/v9/images/apple-touch-icon-144.png',
	display: 'Apple Insider',
},
{
	brand: 'arstechnica',
	url: ['https://arstechnica.com', ],
	logo: 'https://cdn.arstechnica.net/wp-content/themes/ars-mobile/assets/images/material-ars.png',
	display: 'Ars Technica',
},
{
	brand: 'asiafinancial',
	url: ['https://www.asiafinancial.com', ],
	logo: 'https://www.asiafinancial.com/wp-content/uploads/2022/02/af_32x32.png',
	display: 'Asia Financial',
},
{
	brand: 'auntminnie',
	url: ['https://www.auntminnie.com', ],
	logo: 'https://www.auntminnie.com/apple-touch-icon.png',
	display: 'Aunt Minnie',
},
{
	brand: 'autoblog',
	url: ['https://www.autoblog.com', ],
	logo: 'https://www.autoblog.com/v/3f9b72e75baa1516d426d96b9dc6c2ce052c603d/img/favicon/android-chrome-192x192.png?v=2.0.2',
	display: 'Autoblog',
},
{
	brand: 'autonews',
	url: ['https://www.autonews.com', ],
	logo: '',
	display: 'autonews',
},
{
	brand: 'barchart',
	url: ['https://www.barchart.com', ],
	logo: '',
	display: 'Barchart',
},
{
	brand: 'bbc',
	url: ['https://www.bbc.com', ],
	logo: 'https://gn-web-assets.api.bbc.com/wwhp/20240213-1425-fde8fd0287472187a4d8fb1e63e58ad9ea832393/responsive/img/apple-touch/apple-touch-180.jpg',
	display: 'BBC',
},
{
	brand: 'benzinga',
	url: ['https://www.benzinga.com', ],
	logo: 'https://www.benzinga.com/next-assets/images/apple-touch-icon.png',
	display: 'Benzinga',
},
{
	brand: 'besthealthmag',
	url: ['https://www.besthealthmag.ca', ],
	logo: '',
	display: 'besthealthmag',
},
{
	brand: 'bestreviews',
	url: ['https://bestreviews.com', ],
	logo: 'https://bestreviews.com/apple-touch-icon.png',
	display: 'BestReviews',
},
{
	brand: 'bet',
	url: ['https://www.bet.com', ],
	logo: 'https://www.bet.com/apple-touch-icon.png',
	display: 'BET',
},
{
	brand: 'bgr',
	url: ['https://bgr.com', ],
	logo: 'https://bgr.com/wp-content/themes/bgr-2023/assets/images/favicons/android-chrome-192x192.png',
	display: 'BGR',
},
{
	brand: 'bhg',
	url: ['https://www.bhg.com', ],
	logo: 'https://www.bhg.com/apple-touch-icon-180x180.png',
	display: 'Better Homes & Gardens',
},
{
	brand: 'bigthink',
	url: ['https://bigthink.com', ],
	logo: 'https://bigthink.com/wp-content/uploads/2023/06/cropped-bt-icon-512x512-1-1.png?w=192',
	display: 'Big Think',
},
{
	brand: 'billboard',
	url: ['https://www.billboard.com', ],
	logo: 'https://www.billboard.com/wp-content/themes/vip/pmc-billboard-2021/assets/app/icons/apple-touch-icon.png',
	display: 'Billboard',
},
{
	brand: 'biopharmadive',
	url: ['https://www.biopharmadive.com', ],
	logo: 'https://www.biopharmadive.com/apple-touch-icon.png?v=2',
	display: 'Biotech & Pharma Industry',
},
{
	brand: 'bizjournals',
	url: ['https://www.bizjournals.com', ],
	logo: '',
	display: 'bizjournals',
},
{
	brand: 'blackenterprise',
	url: ['https://www.blackenterprise.com', ],
	logo: 'https://www.blackenterprise.com/wp-content/themes/bigdrop-theme/dist/images/favicon/mstile-310x310.png',
	display: 'Black Enterprise',
},
{
	brand: 'bleacherreport',
	url: ['https://bleacherreport.com', ],
	logo: 'https://bleacherreport.com/favicon.ico',
	display: 'B/R',
},
{
	brand: 'bonappetit',
	url: ['https://www.bonappetit.com', ],
	logo: 'https://www.bonappetit.com/apple-touch-icon.png',
	display: 'Bon Appétit',
},
{
	brand: 'bookriot',
	url: ['https://bookriot.com', ],
	logo: 'https://s2982.pcdn.co/wp-content/themes/bookriot/assets/images/favicons/BookRiot_Logo_196.png',
	display: 'Book Riot',
},
{
	brand: 'businessinsider',
	url: ['https://www.businessinsider.com', ],
	logo: 'https://www.businessinsider.com/public/assets/BI/US/favicons/apple-touch-icon-180x180.png?v=2023-11',
	display: 'Business Insider',
},
{
	brand: 'businesswire',
	url: ['https://blog.businesswire.com', 'https://www.businesswire.com', ],
	logo: 'https://blog.businesswire.com/favicon.ico',
	display: 'Business Wire',
},
{
	brand: 'cbssports',
	url: ['https://www.cbssports.com', ],
	logo: 'https://sportsfly.cbsistatic.com/fly-0647/bundles/sportsmediacss/images/core/webclips/apple-touch-icon-180.png',
	display: 'CBS Sports',
},
{
	brand: 'chicagotribune',
	url: ['https://www.chicagotribune.com', ],
	logo: 'https://www.chicagotribune.com/favicon.ico',
	display: 'Chicago Tribune',
},
{
	brand: 'chireviewofbooks',
	url: ['https://chireviewofbooks.com', ],
	logo: 'https://i0.wp.com/chireviewofbooks.com/wp-content/uploads/2021/01/cropped-CRB_Logo_Icon_WhiteOnGreen.png?fit=192%2C192&ssl=1',
	display: 'Chicago Review of Books',
},
{
	brand: 'cnbc',
	url: ['https://www.cnbc.com', ],
	logo: 'https://www.cnbc.com/apple-touch-icon-precomposed.png?region=world',
	display: 'CNBC',
},
{
	brand: 'cnet',
	url: ['https://cnet.com', ],
	logo: 'https://www.cnet.com/favicon-256-v3.png',
	display: 'CNET',
},
{
	brand: 'cnn',
	url: ['https://www.cnn.com', ],
	logo: 'https://edition.cnn.com/media/sites/cnn/apple-touch-icon.png',
	display: 'CNN',
},
{
	brand: 'cntraveler',
	url: ['https://www.cntraveler.com', ],
	logo: 'https://www.cntraveler.com/apple-touch-icon.png',
	display: 'Condé Nast Traveler',
},
{
	brand: 'computerworld',
	url: ['https://www.computerworld.com', ],
	logo: 'https://idge.staticworld.net/ctw/computerworld-logo300x300.png',
	display: 'Computer World',
},
{
	brand: 'cosmopolitan',
	url: ['https://www.cosmopolitan.com', ],
	logo: 'https://www.cosmopolitan.com/_assets/design-tokens/cosmopolitan/static/images/apple-touch-icon.b887080.png',
	display: 'Cosmopolitan',
},
{
	brand: 'crunchbase',
	url: ['https://news.crunchbase.com', ],
	logo: 'https://news.crunchbase.com/wp-content/uploads/fbrfg/apple-touch-icon.png',
	display: 'Crunchbase',
},
{
	brand: 'dailyjournal',
	url: ['https://www.dailyjournal.com', ],
	logo: '',
	display: 'Daily Journal',
},
{
	brand: 'datanami',
	url: ['https://www.datanami.com', ],
	logo: '',
	display: 'datanami',
},
{
	brand: 'deadline',
	url: ['https://deadline.com', ],
	logo: 'https://deadline.com/wp-content/uploads/2019/04/cropped-icon-512x512.png?w=192',
	display: 'Deadline',
},
{
	brand: 'deadspin',
	url: ['https://deadspin.com', ],
	logo: 'https://deadspin.com/apple-touch-icon-precomposed.png',
	display: 'Deadspin',
},
{
	brand: 'dealbreaker',
	url: ['https://dealbreaker.com', ],
	logo: 'https://dealbreaker.com/favicon.ico',
	display: 'Dealbreaker',
},
{
	brand: 'decrypt',
	url: ['https://decrypt.co', ],
	logo: 'https://cdn.decrypt.co/wp-content/themes/decrypt-media/assets/images/favicon-32x32.png',
	display: 'Decrypt',
},
{
	brand: 'digitaltrends',
	url: ['https://www.digitaltrends.com', ],
	logo: 'https://www.digitaltrends.com/wp-content/uploads/2020/08/dt-site-icon.png?fit=144%2C144&p=1',
	display: 'Digital Trends',
},
{
	brand: 'discovermagazine',
	url: ['https://www.discovermagazine.com', ],
	logo: 'https://www.discovermagazine.com/assets/favicon/favicon512.png',
	display: 'Discover Magazine',
},
{
	brand: 'eatingwell',
	url: ['https://www.eatingwell.com', ],
	logo: 'https://www.eatingwell.com/apple-touch-icon-180x180.png',
	display: 'EatingWell',
},
{
	brand: 'economist',
	url: ['https://www.economist.com', ],
	logo: 'https://www.economist.com/engassets/ico/touch-icon-180x180.png',
	display: 'Economist',
},
{
	brand: 'eetimes',
	url: ['https://www.eetimes.com', 'https://www.eetimes.eu', ],
	logo: 'https://www.eetimes.com/wp-content/uploads/2019/12/favicon.ico',
	display: 'EE Times Europe',
},
{
	brand: 'electrek',
	url: ['https://electrek.co', ],
	logo: 'https://electrek.co/wp-content/uploads/sites/3/2017/03/electrek.png?w=192',
	display: 'Electrek',
},
{
	brand: 'eliteagent',
	url: ['https://eliteagent.com', ],
	logo: 'https://media.eliteagent.com/wp-content/uploads/2019/01/29153805/ea-favicon.png',
	display: 'Elite Agent',
},
{
	brand: 'elle',
	url: ['https://www.elle.com', ],
	logo: 'https://www.elle.com/_assets/design-tokens/elle/static/images/apple-touch-icon.0dd915e.png',
	display: 'ELLE',
},
{
	brand: 'engadget',
	url: ['https://www.engadget.com', ],
	logo: 'https://s.yimg.com/kw/assets/favicon-160x160.png',
	display: 'Engadget',
},
{
	brand: 'entrepreneur',
	url: ['https://www.entrepreneur.com', ],
	logo: 'https://www.entrepreneur.com/apple-touch-icon-precomposed.png',
	display: 'Entrepreneur',
},
{
	brand: 'environmentenergyleader',
	url: ['https://www.environmentenergyleader.com/', ],
	logo: 'https://www.environmentenergyleader.com/favicon.ico',
	display: 'Environment+Energy Leader',
},
{
	brand: 'eonline',
	url: ['https://www.eonline.com', ],
	logo: 'https://www.eonline.com/images/icon.png',
	display: 'E! News',
},
{
	brand: 'epicurious',
	url: ['https://www.epicurious.com', ],
	logo: 'https://www.epicurious.com/apple-touch-icon.png',
	display: 'Epicurious',
},
{
	brand: 'escapistmagazine',
	url: ['https://www.escapistmagazine.com', ],
	logo: 'https://www.escapistmagazine.com/wp-content/uploads/2020/04/cropped-escapist-favicon.jpg?fit=192%2C192',
	display: 'The Escapist',
},
{
	brand: 'espn',
	url: ['https://espn.com', ],
	logo: 'https://a.espncdn.com/wireless/mw5/r1/images/bookmark-icons-v2/espn-icon-180x180.png',
	display: 'ESPN',
},
{
	brand: 'etonline',
	url: ['https://www.etonline.com', ],
	logo: 'https://www.etonline.com/img/favicons/android-icon-192x192.png',
	display: 'ET',
},
{
	brand: 'eurogamer',
	url: ['https://www.eurogamer.net', ],
	logo: 'https://www.eurogamer.net/static/581f45f6602069c6b744b97449e553a5/icon/apple-touch-icon-152x152.png',
	display: 'Eurogamer',
},
{
	brand: 'euronews',
	url: ['https://www.euronews.com', ],
	logo: 'https://www.euronews.com/favicon-192x192.png',
	display: 'euronews',
},
{
	brand: 'ew',
	url: ['https://ew.com', ],
	logo: 'https://ew.com/apple-touch-icon-180x180.png',
	display: 'Entertainment Weekly',
},
{
	brand: 'eweek',
	url: ['https://www.eweek.com', ],
	logo: 'https://www.eweek.com/wp-content/uploads/2021/01/eweek_logo_Favicon.png',
	display: 'eWeek',
},
{
	brand: 'fastcompany',
	url: ['https://www.fastcompany.com', ],
	logo: 'https://www.fastcompany.com/favicon.ico',
	display: 'Fast Company',
},
{
	brand: 'fatherly',
	url: ['https://www.fatherly.com', ],
	logo: 'https://cdn2.bustle.com/2024/fatherly/icon-e673033e90.png',
	display: 'Fatherly',
},
{
	brand: 'federalreserve',
	url: ['https://www.federalreserve.gov', ],
	logo: 'https://www.federalreserve.gov/favicon.ico',
	display: 'Federal Reserve',
},
{
	brand: 'financialpost',
	url: ['https://financialpost.com', ],
	logo: 'https://dcs-static.gprod.postmedia.digital/15.5.2/websites/images/apple-touch-icons/iphone-retina/icon-fp.png',
	display: 'Financial Post',
},
{
	brand: 'football365',
	url: ['https://www.football365.com', ],
	logo: 'https://www.football365.com/content/themes/f365-sportsui/img/icons/apple-touch-icon.png?v=23042001',
	display: 'Football365',
},
{
	brand: 'fortune',
	url: ['https://fortune.com', ],
	logo: 'https://fortune.com/favicon.ico',
	display: 'Fortune',
},
{
	brand: 'fourfourtwo',
	url: ['https://www.fourfourtwo.com', ],
	logo: 'https://cdn.mos.cms.futurecdn.net/flexiimages/aec4vfzd5e1583236238.png',
	display: 'FourFourTwo',
},
{
	brand: 'foxbusiness',
	url: ['https://www.foxbusiness.com', ],
	logo: 'https://static.foxnews.com/static/orion/styles/img/fox-business/favicons/android-chrome-192x192.png',
	display: 'Fox Business',
},
{
	brand: 'foxnews',
	url: ['https://www.foxnews.com', ],
	logo: 'https://static.foxnews.com/static/orion/styles/img/fox-news/favicons/android-chrome-192x192.png',
	display: 'Fox News',
},
{
	brand: 'foxsports',
	url: ['https://www.foxsports.com', ],
	logo: 'https://www.foxsports.com/apple-touch-icon.png',
	display: 'Fox Sports',
},
{
	brand: 'ft',
	url: ['https://www.ft.com', 'https://channels.ft.com', ],
	logo: 'https://www.ft.com/__origami/service/image/v2/images/raw/ftlogo-v1%3Abrand-ft-logo-square-coloured?source=update-logos&format=png&width=194&height=194',
	display: 'Financial Times',
},
{
	brand: 'futurism',
	url: ['https://futurism.com', ],
	logo: 'https://futurism.com/favicon.png',
	display: 'Futurism',
},
{
	brand: 'gameinformer',
	url: ['https://www.gameinformer.com', ],
	logo: 'https://www.gameinformer.com/apple-touch-icon.png',
	display: 'Game Informer',
},
{
	brand: 'gamespot',
	url: ['https://www.gamespot.com', ],
	logo: 'https://www.gamespot.com/a/bundles/gamespotsite/images/touch-icon-ipad-retina-precomposed.png',
	display: 'Gamespot',
},
{
	brand: 'gamingbolt',
	url: ['https://gamingbolt.com', ],
	logo: 'https://gamingbolt.com/favicon.ico',
	display: 'Gaming Bolt',
},
{
	brand: 'geeky-gadgets',
	url: ['https://www.geeky-gadgets.com', ],
	logo: 'https://www.geeky-gadgets.com/apple-touch-icon.png',
	display: 'Geeky Gadgets',
},
{
	brand: 'glam',
	url: ['https://www.glam.com', ],
	logo: 'https://www.glam.com/img/glam-favicon-448x448.png',
	display: 'Glam',
},
{
	brand: 'glamour',
	url: ['https://www.glamour.com', ],
	logo: 'https://www.glamour.com/apple-touch-icon-precomposed.png',
	display: 'Glamour',
},
{
	brand: 'go',
	url: ['https://abcnews.go.com', ],
	logo: 'https://abcnews.go.com/apple-touch-icon-precomposed.png',
	display: 'ABC News',
},
{
	brand: 'golfwrx',
	url: ['https://www.golfwrx.com', ],
	logo: 'https://s3.amazonaws.com/golfwrxforums/uploads/monthly_2020_08/golfwrx_favicon.png',
	display: 'GolfWRX',
},
{
	brand: 'gonintendo',
	url: ['https://gonintendo.com', ],
	logo: 'https://gonintendo.com/favicon-192x192.png',
	display: 'GoNintendo',
},
{
	brand: 'gq',
	url: ['https://www.gq.com', ],
	logo: 'https://www.gq.com/apple-touch-icon.png',
	display: 'GQ',
},
{
	brand: 'hackster',
	url: ['https://www.hackster.io', ],
	logo: 'https://prod.hackster-cdn.online/assets/favicons/android-chrome-192x192-a8f6f7fa90d71387fb2c8f3959c2659c41b963d87b0bd028fbb58db16711086a.png?v=zXX3Bm3lo3" sizes="192x192',
	display: 'Hackster',
},
{
	brand: 'halfbakedharvest',
	url: ['https://www.halfbakedharvest.com', ],
	logo: 'https://www.halfbakedharvest.com/wp-content/uploads/2021/10/cropped-favicon-192x192.png',
	display: 'Half Baked Harvest',
},
{
	brand: 'harvard',
	url: ['https://www.health.harvard.edu/', 'https://news.harvard.edu', ],
	logo: 'https://hhp-assets.s3.amazonaws.com/icon/android-chrome-192x192.png',
	display: 'The Harvard Gazette',
},
{
	brand: 'hbr',
	url: ['https://hbr.org', ],
	logo: 'https://hbr.org/apple-touch-icon-precomposed.png',
	display: 'Havard Business Review',
},
{
	brand: 'health',
	url: ['https://www.health.com', ],
	logo: 'https://www.health.com/apple-touch-icon-180x180.png',
	display: 'Health',
},
{
	brand: 'healthcaredive',
	url: ['https://www.healthcaredive.com', ],
	logo: 'https://www.healthcaredive.com/apple-touch-icon.png?v=2',
	display: 'Healthcare Dive',
},
{
	brand: 'healthcareittoday',
	url: ['https://www.healthcareittoday.com', ],
	logo: 'https://www.healthcareittoday.com/favicon.ico',
	display: 'Healthcare IT Today',
},
{
	brand: 'heritage',
	url: ['https://www.heritage.org', ],
	logo: 'https://www.heritage.org/themes/heritage/src/images/favicon/apple-touch-icon.png',
	display: 'Heritage',
},
{
	brand: 'hollywoodlife',
	url: ['https://hollywoodlife.com', ],
	logo: 'https://hollywoodlife.com/wp-content/uploads/2023/09/hollywoodlife_icon-2024_sm.png?quality=100&w=100',
	display: 'Hollywood Life',
},
{
	brand: 'hollywoodreporter',
	url: ['https://www.hollywoodreporter.com', ],
	logo: 'https://www.hollywoodreporter.com/wp-content/themes/vip/pmc-hollywoodreporter-2021/assets/app/icons/favicon.png',
	display: 'Hollywood Reporter',
},
{
	brand: 'huffpost',
	url: ['https://www.huffpost.com', ],
	logo: 'https://www.huffpost.com/favicon.ico',
	display: 'HuffPost',
},
{
	brand: 'hypebeast',
	url: ['https://hypebeast.com', ],
	logo: 'https://hypebeast.com/dist/hypebeast/favicon/apple-touch-icon-1024x1024.png?v4',
	display: 'Hypebeast',
},
{
	brand: 'ieee',
	url: ['https://spectrum.ieee.org', ],
	logo: 'https://assets.rebelmouse.io/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbWFnZSI6Imh0dHBzOi8vYXNzZXRzLnJibC5tcy8yNjU5NjY0OS9vcmlnaW4ucG5nIiwiZXhwaXJlc19hdCI6MTcyMjY3NDUwNn0.E9lMSNv23A_GyW60aP_Rh5AzMMAKAHMpGrotgTDO5_Q/img.png?width=192&height=192',
	display: 'IEEE',
},
{
	brand: 'ign',
	url: ['https://www.ign.com', ],
	logo: 'https://kraken.ignimgs.com/favicon.ico',
	display: 'IGN',
},
{
	brand: 'independent',
	url: ['https://www.independent.co.uk', ],
	logo: 'https://www.independent.co.uk/img/shortcut-icons/icon-512x512.png',
	display: 'Independent',
},
{
	brand: 'infoworld',
	url: ['https://www.infoworld.com', ],
	logo: 'https://idge.staticworld.net/ifw/IFW_logo_social_300x300.png',
	display: 'InfoWorld',
},
{
	brand: 'insideevs',
	url: ['https://insideevs.com', ],
	logo: 'https://cdn.motor1.com/images/static/insideevs/favicon-228.png',
	display: 'InsideEVs',
},
{
	brand: 'institutionalinvestor',
	url: ['https://www.institutionalinvestor.com', ],
	logo: 'https://www.institutionalinvestor.com/favicon.ico',
	display: 'Institutional Investor',
},
{
	brand: 'interestingengineering',
	url: ['https://interestingengineering.com', ],
	logo: 'https://interestingengineering.com/icons/android-icon-192x192.png?v=14',
	display: 'Interesting Engineering',
},
{
	brand: 'investing',
	url: ['https://www.investing.com', ],
	logo: 'https://www.investing.com/favicon.ico',
	display: 'Investing',
},
{
	brand: 'investopedia',
	url: ['https://www.investopedia.com', ],
	logo: 'https://www.investopedia.com/apple-touch-icon-180x180.png',
	display: 'Investopedia',
},
{
	brand: 'investorplace',
	url: ['https://investorplace.com', ],
	logo: 'https://investorplace.com/wp-content/uploads/2020/07/cropped-ip-512x512-1-1-192x192.png',
	display: 'InvestorPlace',
},
{
	brand: 'investors',
	url: ['https://www.investors.com', ],
	logo: 'https://www.investors.com/static/favicon.ico',
	display: 'Investor’s Business Daily',
},
{
	brand: 'jalopnik',
	url: ['https://jalopnik.com', ],
	logo: 'https://i.kinja-img.com/image/upload/c_fill,h_200,q_80,w_200/341329e55edf8a8a922929e6eb19ebde.png',
	display: 'Jalopnik',
},
{
	brand: 'joblo',
	url: ['https://www.joblo.com', ],
	logo: 'https://www.joblo.com/apple-touch-icon.png',
	display: 'JoBlo',
},
{
	brand: 'kiplinger',
	url: ['https://www.kiplinger.com', ],
	logo: '',
	display: 'Kiplinger',
},
{
	brand: 'koreaboo',
	url: ['https://www.koreaboo.com', ],
	logo: 'https://www.koreaboo.com/wp-content/themes/KbooOS2/img/site_icon/apple-touch-icon.png',
	display: 'koreaboo',
},
{
	brand: 'kotaku',
	url: ['https://kotaku.com', ],
	logo: 'https://i.kinja-img.com/image/upload/c_fill,h_200,q_80,w_200/v4sckews2f3bzf0ztbkf.png',
	display: 'Kotaku',
},
{
	brand: 'kpopstarz',
	url: ['https://www.kpopstarz.com', ],
	logo: 'https://1925807164.rsc.cdn77.org/static/common/_v0.0.0/favicon.ico',
	display: 'KpopStarz',
},
{
	brand: 'latimes',
	url: ['https://www.latimes.com', ],
	logo: 'https://www.latimes.com/favicon.ico',
	display: 'Los Angeles Times',
},
{
	brand: 'lifehacker',
	url: ['https://lifehacker.com', ],
	logo: 'https://lifehacker.com/images/favicon-16x16.png',
	display: 'Lifehacker',
},
{
	brand: 'lifewire',
	url: ['https://www.lifewire.com', ],
	logo: 'https://www.lifewire.com/apple-touch-icon-180x180.png',
	display: 'Lifewire',
},
{
	brand: 'lithub',
	url: ['https://lithub.com', ],
	logo: 'https://s26162.pcdn.co/wp-content/uploads/2015/04/favicon.ico',
	display: 'Literary Hub',
},
{
	brand: 'macworld',
	url: ['https://www.macworld.com', ],
	logo: 'https://www.macworld.com/wp-content/uploads/2021/03/cropped-macworld-favicon.png?w=192',
	display: 'Macworld',
},
{
	brand: 'marketbeat',
	url: ['https://www.marketbeat.com', ],
	logo: 'https://www.marketbeat.com/apple-touch-icon-180x180.png',
	display: 'MarketBeat',
},
{
	brand: 'marketwatch',
	url: ['https://www.marketwatch.com', ],
	logo: 'https://mw4.wsj.net/mw5/content/images/favicons/apple-touch-icon-180x180.png',
	display: 'MarketWatch',
},
{
	brand: 'marktechpost',
	url: ['https://www.marktechpost.com', ],
	logo: 'https://www.marktechpost.com/wp-content/uploads/2021/04/AI-PAPERS-SUMMARIES-1.png',
	display: 'MarkTechPost',
},
{
	brand: 'martech',
	url: ['https://martech.org', ],
	logo: 'https://martech.org/wp-content/themes/tdm-editorial/apple-touch-icon.png',
	display: 'MarTech',
},
{
	brand: 'maxim',
	url: ['https://www.maxim.com', ],
	logo: 'https://www.maxim.com/wp-content/uploads/2021/05/cropped-favicon.png?w=192',
	display: 'Maxim',
},
{
	brand: 'mcall',
	url: ['https://www.mcall.com', ],
	logo: 'https://www.mcall.com/wp-content/uploads/2023/01/favicon.png?w=32',
	display: 'The Morning Call',
},
{
	brand: 'medcitynews',
	url: ['https://medcitynews.com', ],
	logo: 'https://medcitynews.com/wp-content/themes/medcitynews/images/favicon.ico',
	display: 'MedCity News',
},
{
	brand: 'medium',
	url: ['https://blog.medium.com', 'https://medium.com', ],
	logo: 'https://cdn-images-1.medium.com/fit/c/304/304/1*sHhtYhaCe2Uc3IU0IgKwIQ.png',
	display: 'Medium',
},
{
	brand: 'mises',
	url: ['https://mises.org', ],
	logo: 'https://mises.org/apple-touch-icon-precomposed.png',
	display: 'Mises Institute',
},
{
	brand: 'mit',
	url: ['https://news.mit.edu', ],
	logo: 'https://news.mit.edu/themes/mit/assets/img/favicon/apple-touch-icon.png',
	display: 'MIT News',
},
{
	brand: 'mlb',
	url: ['https://www.mlb.com', ],
	logo: 'https://builds.mlbstatic.com/mlb.com/builds/site-core/1606751303311/dist/images/favicon.png',
	display: 'MLB',
},
{
	brand: 'mlssoccer',
	url: ['https://www.mlssoccer.com', ],
	logo: 'https://images.mlssoccer.com/image/upload/assets/logos/apple-touch-icon.png',
	display: 'MLS Soccer',
},
{
	brand: 'moneyweek',
	url: ['https://moneyweek.com', ],
	logo: '',
	display: 'moneyweek',
},
{
	brand: 'moneywise',
	url: ['https://moneywise.com', ],
	logo: '',
	display: 'moneywise',
},
{
	brand: 'morningbrew',
	url: ['https://morningbrew.com', ],
	logo: '',
	display: 'Morning Brew',
},
{
	brand: 'morningstar',
	url: ['https://www.morningstar.com', ],
	logo: 'https://www.morningstar.com/favicon-512.png',
	display: 'Morningstar',
},
{
	brand: 'motherjones',
	url: ['https://www.motherjones.com', ],
	logo: 'https://www.motherjones.com/wp-content/uploads/2017/09/cropped-favicon-512x512.png?w=192',
	display: 'Mother Jones',
},
{
	brand: 'motorsportnetwork',
	url: ['https://www.motorsportnetwork.com', ],
	logo: 'https://www.motorsportnetwork.com/design/webapp/i192.png',
	display: 'Motorsport Network',
},
{
	brand: 'mru',
	url: ['https://mru.org', ],
	logo: '',
	display: 'mru',
},
{
	brand: 'msnbc',
	url: ['https://www.msnbc.com', ],
	logo: 'https://nodeassets.nbcnews.com/cdnassets/projects/ramen/favicon/msnbc/all-other-sizes-PNG.ico/android-icon-192x192.png',
	display: 'MSNBC News',
},
{
	brand: 'nasa',
	url: ['https://www.nasa.gov', ],
	logo: 'https://www.nasa.gov/wp-content/plugins/nasa-hds-core-setup/assets/favicons/favicon-228x228.png',
	display: 'NASA',
},
{
	brand: 'nascar',
	url: ['https://www.nascar.com', ],
	logo: '',
	display: 'nascar',
},
{
	brand: 'nationalgeographic',
	url: ['https://www.nationalgeographic.com', ],
	logo: 'https://assets-cdn.nationalgeographic.com/natgeo/static/icons/favicon-180x180.png',
	display: 'National Geographic',
},
{
	brand: 'nationalmortgagenews',
	url: ['https://www.nationalmortgagenews.com', ],
	logo: 'https://www.nationalmortgagenews.com/apple-touch-icon-precomposed.png',
	display: 'National Mortgage News',
},
{
	brand: 'nationalreview',
	url: ['https://www.nationalreview.com', ],
	logo: 'https://www.nationalreview.com/wp-content/themes/national-review/static/images/favicons/android-icon-192x192.png',
	display: 'National Review',
},
{
	brand: 'nature',
	url: ['https://www.nature.com', ],
	logo: 'https://www.nature.com/static/images/favicons/nature/apple-touch-icon-f39cb19454.png',
	display: 'Nature',
},
{
	brand: 'nba',
	url: ['https://www.nba.com', ],
	logo: 'https://www.nba.com/apple-touch-icon.png',
	display: 'NBA',
},
{
	brand: 'nbcnews',
	url: ['https://www.nbcnews.com', ],
	logo: 'https://nodeassets.nbcnews.com/cdnassets/projects/ramen/favicon/nbcnews/all-other-sizes-PNG.ico/android-icon-192x192.png',
	display: 'NBC News',
},
{
	brand: 'nbcsports',
	url: ['https://www.nbcsports.com', ],
	logo: 'https://www.nbcsports.com/apple-touch-icon.png',
	display: 'NBC Sports',
},
{
	brand: 'news-medical',
	url: ['https://www.news-medical.net', ],
	logo: 'https://www.news-medical.net/favicon-96x96.png',
	display: 'Medical News',
},
{
	brand: 'newscientist',
	url: ['https://www.newscientist.com', ],
	logo: 'https://www.newscientist.com/build/images/layup/touch-icon/180x180.50b024f6.png',
	display: 'New Scientist',
},
{
	brand: 'newyorker',
	url: ['https://www.newyorker.com', ],
	logo: 'https://www.newyorker.com/apple-touch-icon.png',
	display: 'The New Yorker',
},
{
	brand: 'nextplatform',
	url: ['https://www.nextplatform.com', ],
	logo: 'https://cdn-egkobdl.nitrocdn.com/ulDjIpGUZhYaRUNKrOseVHspfYvwUUHP/assets/images/optimized/wp-content/uploads/2015/04/87759b00d23db9ca55b69a29fd61b416.cropped-PlatformStack-300x300.jpg',
	display: 'The Next Platform',
},
{
	brand: 'nfl',
	url: ['https://www.nfl.com', ],
	logo: 'https://static.www.nfl.com/league/run3dfyjniqxah4ehxfu',
	display: 'NFL',
},
{
	brand: 'nih',
	url: ['https://www.nih.gov', ],
	logo: 'https://www.nih.gov/sites/all/themes/nih/apple-touch-icon.png',
	display: 'National Institutes of Health',
},
{
	brand: 'nikkei',
	url: ['https://asia.nikkei.com', ],
	logo: 'https://asia.nikkei.com/apple-touch-icon-288x288.png?ez',
	display: 'Nikkei Asia',
},
{
	brand: 'nintendoeverything',
	url: ['https://nintendoeverything.com', ],
	logo: 'https://nintendoeverything.com/wp-content/uploads/cropped-OLmaPV3Y_400x400-1.jpg',
	display: 'Nintendo Everything',
},
{
	brand: 'nme',
	url: ['https://www.nme.com', ],
	logo: 'https://www.nme.com/wp-content/uploads/2019/12/logo-nme@64w.png',
	display: 'NME',
},
{
	brand: 'npr',
	url: ['https://www.npr.org', ],
	logo: 'https://media.npr.org/chrome/favicon/favicon-180x180.png',
	display: 'NPR',
},
{
	brand: 'nypost',
	url: ['https://nypost.com', ],
	logo: 'https://nypost.com/wp-content/themes/nypost-2016/static/images/favicons/favicon.ico',
	display: 'New York Post',
},
{
	brand: 'nytimes',
	url: ['https://www.nytimes.com', 'https://cn.nytimes.com', 'https://cooking.nytimes.com', ],
	logo: 'https://www.nytimes.com/vi-assets/static-assets/apple-touch-icon-28865b72953380a40aa43318108876cb.png',
	display: 'The New York Times',
},
{
	brand: 'observer',
	url: ['https://observer.com', ],
	logo: 'https://observer.com/wp-content/themes/newyorkobserver-2014/images/favicons/favicon-194x194.png',
	display: 'Observer',
},
{
	brand: 'onegreenplanet',
	url: ['https://www.onegreenplanet.org/', ],
	logo: 'https://www.onegreenplanet.org/wp-content/themes/onegreenplanet/img/apple-touch-icon-114x114.png',
	display: 'One Green Planet',
},
{
	brand: 'outsports',
	url: ['https://www.outsports.com', ],
	logo: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/24185036/outsports_minimal.png',
	display: 'Outsports',
},
{
	brand: 'pcgamer',
	url: ['https://www.pcgamer.com', ],
	logo: 'https://vanilla.futurecdn.net/pcgamer/902121/apple-touch-icon.png',
	display: 'PC Gamer',
},
{
	brand: 'pcgamesn',
	url: ['https://www.pcgamesn.com', ],
	logo: 'https://www.pcgamesn.com/wp-content/themes/pcgamesn/dist/images/favicon/apple-touch-icon.png',
	display: 'PCGamesN',
},
{
	brand: 'pcmag',
	url: ['https://www.pcmag.com', ],
	logo: '',
	display: 'PC Magazine',
},
{
	brand: 'pcworld',
	url: ['https://www.pcworld.com', ],
	logo: 'https://www.pcworld.com/wp-content/uploads/2021/09/cropped-cropped-PCWorld-Site-Icon.png?w=192',
	display: 'PCWorld',
},
{
	brand: 'people',
	url: ['https://people.com', ],
	logo: 'https://people.com/apple-touch-icon-180x180.png',
	display: 'People',
},
{
	brand: 'phys',
	url: ['https://phys.org', ],
	logo: 'https://phys.b-cdn.net/favicon.ico',
	display: 'Phys',
},
{
	brand: 'physiciansweekly',
	url: ['https://www.physiciansweekly.com', ],
	logo: '',
	display: 'Physician’s Weekly',
},
{
	brand: 'planetfootball',
	url: ['https://www.planetfootball.com', ],
	logo: 'https://www.planetfootball.com/content/themes/planetfootball-sportsui/img/icons/apple-touch-icon.png',
	display: 'Planet Football',
},
{
	brand: 'playstationlifestyle',
	url: ['https://www.playstationlifestyle.net', ],
	logo: 'https://www.playstationlifestyle.net/wp-content/uploads/sites/9/2023/03/cropped-favicon.png?w=192',
	display: 'PlayStation Lifestyle',
},
{
	brand: 'politico',
	url: ['https://www.politico.com', ],
	logo: '',
	display: 'Politico',
},
{
	brand: 'polygon',
	url: ['https://www.polygon.com', ],
	logo: 'https://cdn.vox-cdn.com/uploads/hub/sbnu_logo_minimal/405/touch_icon_ipad_retina_1000x1000.7014.png',
	display: 'Polygon',
},
{
	brand: 'popsci',
	url: ['https://www.popsci.com', ],
	logo: 'https://www.popsci.com/uploads/2021/04/28/cropped-PSC3.png?auto=webp&width=192&height=192',
	display: 'Popular Science',
},
{
	brand: 'popsugar',
	url: ['https://www.popsugar.com', ],
	logo: 'https://media1.popsugar-assets.com/v4829/static/imgs/apple/apple-touch-icon-180x180.png',
	display: 'POPSUGAR',
},
{
	brand: 'powermag',
	url: ['https://www.powermag.com', ],
	logo: 'https://www.powermag.com/wp-content/uploads/2020/11/cropped-p-power-192x192.jpg',
	display: 'POWER Magazine',
},
{
	brand: 'psypost',
	url: ['https://www.psypost.org', ],
	logo: 'https://www.psypost.org/favicon.ico',
	display: 'PsyPost',
},
{
	brand: 'pymnts',
	url: ['https://www.pymnts.com', ],
	logo: 'https://www.pymnts.com/wp-content/uploads/2022/08/android-chrome-192x192-1.png',
	display: 'PYMNTS',
},
{
	brand: 'quantamagazine',
	url: ['https://www.quantamagazine.org', ],
	logo: 'https://www.quantamagazine.org/apple-touch-icon.png',
	display: 'Quanta Magazine',
},
{
	brand: 'realsimple',
	url: ['https://www.realsimple.com', ],
	logo: 'https://www.realsimple.com/apple-touch-icon-180x180.png',
	display: 'Real Simple',
},
{
	brand: 'reason',
	url: ['https://reason.com', ],
	logo: 'https://d2eehagpk5cl65.cloudfront.net/wp-content/themes/reason-dot-com-theme/dist/images/favicon/android-icon-192x192_5c5b801e.png',
	display: 'Reason',
},
{
	brand: 'restofworld',
	url: ['https://restofworld.org', ],
	logo: 'https://149346090.v2.pressablecdn.com/wp-content/themes/orbis/assets/icons/icon-196.png',
	display: 'Rest of World',
},
{
	brand: 'rideapart',
	url: ['https://www.rideapart.com', ],
	logo: 'https://cdn.motor1.com/images/static/rideapart/favicon-228.png',
	display: 'RideApart',
},
{
	brand: 'rivals',
	url: ['https://www.rivals.com', ],
	logo: 'https://cdn.rivals.com/production/assets/favicons/favicon-194x194-996e0c79a474a05a209d8c12b87f66cd2eaec0187153d50f0ae71e0c4e85b94e.png',
	display: 'Rivals',
},
{
	brand: 'robbreport',
	url: ['https://robbreport.com', 'https://robbreport.co.uk', ],
	logo: 'https://robbreport.com/wp-content/uploads/2018/04/cropped-robbreport_favicon.png?w=192',
	display: 'Robb Report UK',
},
{
	brand: 'sandiegouniontribune',
	url: ['https://www.sandiegouniontribune.com', ],
	logo: 'https://www.sandiegouniontribune.com/apple-touch-icon-precomposed.png',
	display: 'The San Diego Union-Tribune',
},
{
	brand: 'saveur',
	url: ['https://www.saveur.com', ],
	logo: 'https://www.saveur.com/uploads/2021/06/22/Saveur_FAV_CRM-1.png?auto=webp',
	display: 'Saveur',
},
{
	brand: 'sbnation',
	url: ['https://www.sbnation.com', ],
	logo: 'https://cdn1.vox-cdn.com/dev/uploads/chorus_asset/file/8108503/sandbox-www-data-ip-10-0-0-66_/sandbox_favicon-192x192.0.png',
	display: 'SBNation',
},
{
	brand: 'sci',
	url: ['https://www.sci.news', ],
	logo: 'https://cdn.sci.news/apple-touch-icon-precomposed.png',
	display: 'Sci.News',
},
{
	brand: 'science',
	url: ['https://www.science.org', ],
	logo: '',
	display: 'science',
},
{
	brand: 'sciencenews',
	url: ['https://www.sciencenews.org', ],
	logo: 'https://i0.wp.com/www.sciencenews.org/wp-content/uploads/2019/08/SN_favicon.png?fit=186%2C186&ssl=1',
	display: 'Science News',
},
{
	brand: 'scientificamerican',
	url: ['https://www.scientificamerican.com', ],
	logo: 'https://www.scientificamerican.com/favicon.ico',
	display: 'Scientific American',
},
{
	brand: 'scitechdaily',
	url: ['https://scitechdaily.com', ],
	logo: 'https://scitechdaily.com/favicon.ico',
	display: 'SciTechDaily',
},
{
	brand: 'seekingalpha',
	url: ['https://seekingalpha.com', ],
	logo: 'https://seekingalpha.com/samw/static/images/favicon-192x192.png',
	display: 'Seeking Alpha',
},
{
	brand: 'self',
	url: ['https://www.self.com', ],
	logo: 'https://www.self.com/apple-touch-icon.png',
	display: 'SELF Magazine',
},
{
	brand: 'semafor',
	url: ['https://www.semafor.com', ],
	logo: 'https://www.semafor.com/apple-touch-icon.png',
	display: 'Semafor',
},
{
	brand: 'sifted',
	url: ['https://sifted.eu', ],
	logo: 'https://sifted.eu/favicon.ico',
	display: 'Sifted EU',
},
{
	brand: 'siliconera',
	url: ['https://www.siliconera.com', ],
	logo: 'https://d3la0uqcqx40x5.cloudfront.net/wp-content/uploads/2021/04/cropped-cropped-favicon-new-270x270-1.jpg?fit=180%2C180',
	display: 'Siliconera',
},
{
	brand: 'siliconrepublic',
	url: ['https://www.siliconrepublic.com', ],
	logo: 'https://www.siliconrepublic.com/apple-touch-icon-152x152.png',
	display: 'Silicon Republic',
},
{
	brand: 'siliconvalley',
	url: ['https://www.siliconvalley.com', ],
	logo: 'https://www.siliconvalley.com/wp-content/uploads/2016/10/32x32-sv-favicon-1.jpg?w=67',
	display: 'SiliconValley',
},
{
	brand: 'sky',
	url: ['https://news.sky.com', ],
	logo: 'https://news.sky.com/apple-touch-icon.png',
	display: 'Sky News',
},
{
	brand: 'soompi',
	url: ['https://www.soompi.com', ],
	logo: 'https://0.soompi.io/soompi-web/favicon.ico',
	display: 'Soompi',
},
{
	brand: 'southernliving',
	url: ['https://www.southernliving.com', ],
	logo: 'https://www.southernliving.com/apple-touch-icon-180x180.png',
	display: 'Southern Living',
},
{
	brand: 'space',
	url: ['https://www.space.com', ],
	logo: 'https://vanilla.futurecdn.net/space/902121/apple-touch-icon.png',
	display: 'Space',
},
{
	brand: 'spacenews',
	url: ['https://spacenews.com', ],
	logo: 'https://i0.wp.com/spacenews.com/wp-content/uploads/2024/01/cropped-512.png?fit=192%2C192&ssl=1',
	display: 'SpaceNews',
},
{
	brand: 'spring',
	url: ['https://www.spring.org.uk', ],
	logo: 'https://www.spring.org.uk/apple-touch-icon-precomposed.png',
	display: 'PsyBlog',
},
{
	brand: 'stanford',
	url: ['https://law.stanford.edu', 'https://news.stanford.edu', ],
	logo: 'https://law.stanford.edu/wp-content/themes/stanford-law-school/img/branding/apple-touch-icon-precomposed.png',
	display: 'stanford',
},
{
	brand: 'stateline',
	url: ['https://stateline.org', ],
	logo: 'https://stateline.org/wp-content/uploads/2023/04/cropped-Stateline-graphic-site-192x192.png',
	display: 'Stateline',
},
{
	brand: 'syncedreview',
	url: ['https://syncedreview.com', ],
	logo: 'https://i0.wp.com/syncedreview.com/wp-content/uploads/2017/08/cropped-screen-shot-2017-08-01-at-2-02-47-pm.png?fit=192%2C192&ssl=1',
	display: 'Synced',
},
{
	brand: 'talkandroid',
	url: ['https://www.talkandroid.com', ],
	logo: 'https://www.talkandroid.com/wp-content/uploads/2023/12/cropped-Asset-7@2x-8-1-192x192.png',
	display: 'Talk Android',
},
{
	brand: 'tech',
	url: ['https://tech.co', ],
	logo: 'https://tech.co/wp-content/themes/techco/dist/img/favicon/apple-touch-icon.png',
	display: 'Tech.co',
},
{
	brand: 'techcrunch',
	url: ['https://techcrunch.com', ],
	logo: 'https://techcrunch.com/favicon.ico',
	display: 'TechCrunch',
},
{
	brand: 'techdigest',
	url: ['https://www.techdigest.tv', ],
	logo: 'https://i0.wp.com/www.techdigest.tv/wp-content/uploads/2015/04/tech-digest-favicon.jpg?fit=16%2C16&ssl=1',
	display: 'Tech Digest',
},
{
	brand: 'techiexpert',
	url: ['https://www.techiexpert.com', ],
	logo: 'https://www.techiexpert.com/wp-content/uploads/2024/02/cropped-TechiExpert-Favicon-192x192.png',
	display: 'Techiexpert',
},
{
	brand: 'technologyreview',
	url: ['https://www.technologyreview.com', ],
	logo: 'https://www.technologyreview.com/static/media/favicon.1cfcdb44759a0f93ddf5feb5405dd4cc.ico',
	display: 'MIT Technology Review',
},
{
	brand: 'techradar',
	url: ['https://www.techradar.com', ],
	logo: 'https://vanilla.futurecdn.net/techradar/902121/apple-touch-icon.png',
	display: 'TechRadar',
},
{
	brand: 'techreport',
	url: ['https://techreport.com', ],
	logo: 'https://techreport.com/wp-content/themes/sage/resources/images/favicon/techreport/apple-touch-icon.png',
	display: 'The Tech Report',
},
{
	brand: 'techspot',
	url: ['https://www.techspot.com', ],
	logo: 'https://www.techspot.com/apple-touch-icon-precomposed.png',
	display: 'TechSpot',
},
{
	brand: 'techxplore',
	url: ['https://techxplore.com', ],
	logo: 'https://techxplore.com/favicon.ico',
	display: 'Tech Xplore',
},
{
	brand: 'ted',
	url: ['https://blog.ted.com', 'https://www.ted.com', 'https://ideas.ted.com', ],
	logo: 'https://blog.ted.com/wp-content/uploads/sites/2/2023/08/cropped-TED-circle-logo-512x512-1.png?w=192',
	display: 'TED',
},
{
	brand: 'telegraph',
	url: ['https://secure.telegraph.co.uk', 'https://telegraph.co.uk', ],
	logo: 'https://www.telegraph.co.uk/etc.clientlibs/settings/wcm/designs/telegraph/core/clientlibs/core/resources/icons/favicon-196x196.png',
	display: 'The Telegraph',
},
{
	brand: 'tennis',
	url: ['https://www.tennis.com', ],
	logo: 'https://www.tennis.com/assets/icons/favicon.ico',
	display: 'Tennis',
},
{
	brand: 'the-scientist',
	url: ['https://www.the-scientist.com', ],
	logo: 'https://cdn.the-scientist.com/static/7.6.4/favicon.ico',
	display: 'The Scientist',
},
{
	brand: 'the-sun',
	url: ['https://www.the-sun.com', ],
	logo: 'https://www.the-sun.com/apple-touch-icon-precomposed.png',
	display: 'The Sun',
},
{
	brand: 'theatlantic',
	url: ['https://www.theatlantic.com', ],
	logo: 'https://cdn.theatlantic.com/_next/static/images/apple-touch-icon-152x152-aafde20dd981a38fcd549b29b2b3b785.png',
	display: 'The Atlantic',
},
{
	brand: 'thebalancemoney',
	url: ['https://www.thebalancemoney.com', ],
	logo: 'https://www.thebalancemoney.com/apple-touch-icon-180x180.png',
	display: 'The Balance',
},
{
	brand: 'thebusinessjournal',
	url: ['https://thebusinessjournal.com', ],
	logo: 'https://tbjlive.wpenginepowered.com/wp-content/uploads/2017/06/cropped-tbj-favicon2-192x192.png',
	display: 'The Business Journal',
},
{
	brand: 'thecomeback',
	url: ['https://thecomeback.com', ],
	logo: '',
	display: 'The Comeback',
},
{
	brand: 'theconversation',
	url: ['https://theconversation.com', ],
	logo: 'https://cdn.theconversation.com/static/tc/@theconversation/ui/dist/esm/logos/web-app-logo-192x192-2d05bdd6de6328146de80245d4685946.png',
	display: 'The Conversation',
},
{
	brand: 'thecooldown',
	url: ['https://www.thecooldown.com', ],
	logo: 'https://www.thecooldown.com/wp-content/uploads/2022/10/Logo-TCD-Viridis_IG.jpg?w=192',
	display: 'The Cool Down',
},
{
	brand: 'thecut',
	url: ['https://www.thecut.com', ],
	logo: 'https://assets.thecut.com/media/sites/wwwthecut/icon.196x196.png',
	display: 'The Cut',
},
{
	brand: 'thedailybeast',
	url: ['https://www.thedailybeast.com', ],
	logo: 'https://www.thedailybeast.com/static/media/favicon.b30a79ed.ico',
	display: 'The Daily Beast',
},
{
	brand: 'thediplomat',
	url: ['https://thediplomat.com', ],
	logo: 'https://thediplomat.com/favicon.ico',
	display: 'The Diplomat',
},
{
	brand: 'thedrive',
	url: ['https://link.thedrive.com', 'https://www.thedrive.com', ],
	logo: 'https://www.thedrive.com/uploads/2021/12/14/favicon-196x196-1.png?auto=webp',
	display: 'The Drive',
},
{
	brand: 'thefintechtimes',
	url: ['https://thefintechtimes.com', ],
	logo: 'https://thefintechtimes.com/wp-content/uploads/2018/08/Logo-usable-for-image-creation-1.jpg',
	display: 'The Fintech Times',
},
{
	brand: 'theglobeandmail',
	url: ['https://www.theglobeandmail.com', ],
	logo: 'https://www.theglobeandmail.com/pf/resources/assets/meta/apple-touch-icon.png?d=504',
	display: 'The Globe and Mail',
},
{
	brand: 'theguardian',
	url: ['https://www.theguardian.com', ],
	logo: 'https://assets.guim.co.uk/static/frontend/icons/homescreen/apple-touch-icon-512.png',
	display: 'The Guardian',
},
{
	brand: 'theinformation',
	url: ['https://www.theinformation.com', ],
	logo: 'https://ti-assets.theinformation.com/assets/favicon_prod/apple-touch-icon-5c1a502d81acf6d03a5e93bf4384f77b6879bead5a9a5039e1cbb6af17038d58.png',
	display: 'The Information',
},
{
	brand: 'thenextweb',
	url: ['https://thenextweb.com', ],
	logo: 'https://next.tnwcdn.com/assets/img/favicon/favicon-194x194.png',
	display: 'TNW',
},
{
	brand: 'theregister',
	url: ['https://www.theregister.com', ],
	logo: 'https://www.theregister.com/design_picker/13249a2e80709c7ff2e57dd3d49801cd534f2094/graphics/favicons/apple-touch-icon.png',
	display: 'The Register',
},
{
	brand: 'theringer',
	url: ['https://www.theringer.com', ],
	logo: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/8991995/favicon-196x196.0.png',
	display: 'The Ringer',
},
{
	brand: 'therobotreport',
	url: ['https://www.therobotreport.com', ],
	logo: 'https://www.therobotreport.com/wp-content/uploads/2017/08/cropped-robot-report-site-192x192.png',
	display: 'The Robot Report',
},
{
	brand: 'thestreet',
	url: ['https://www.thestreet.com', ],
	logo: 'https://www.thestreet.com/favicon.ico',
	display: 'The Street',
},
{
	brand: 'thetradenews',
	url: ['https://www.thetradenews.com', ],
	logo: 'https://thetradenews.b-cdn.net/wp-content/themes/thetrade/images/favicon180x180.png',
	display: 'The TRADE',
},
{
	brand: 'theverge',
	url: ['https://www.theverge.com', ],
	logo: 'https://www.theverge.com/icons/android_chrome_512x512.png',
	display: 'The Verge',
},
{
	brand: 'theweek',
	url: ['https://theweek.com', ],
	logo: '',
	display: 'The Week',
},
{
	brand: 'thewrap',
	url: ['https://www.thewrap.com', ],
	logo: 'https://i0.wp.com/www.thewrap.com/wp-content/uploads/2023/07/thewrap-site-icon-1.png?fit=192%2C192&ssl=1',
	display: 'TheWrap',
},
{
	brand: 'thurrott',
	url: ['https://www.thurrott.com', ],
	logo: 'https://thurrott-assets.nyc3.digitaloceanspaces.com/web/2020/07/logo-favicon.png',
	display: 'Thurrott',
},
{
	brand: 'time',
	url: ['https://time.com', ],
	logo: 'https://time.com/img/favicons/favicon-192.png',
	display: 'Time',
},
{
	brand: 'tmz',
	url: ['https://www.tmz.com', ],
	logo: 'https://static.tmz.com/tmz-web/icons/android-chrome-512x512-v1.png',
	display: 'TMZ',
},
{
	brand: 'today',
	url: ['https://www.today.com', ],
	logo: 'https://nodeassets.nbcnews.com/cdnassets/projects/ramen/favicon/today/all-other-sizes-PNG.ico/android-icon-192x192.png',
	display: 'Today',
},
{
	brand: 'tomshardware',
	url: ['https://www.tomshardware.com', ],
	logo: 'https://vanilla.futurecdn.net/tomshardware/902121/apple-touch-icon.png',
	display: 'Tom’s Hardware',
},
{
	brand: 'topgear',
	url: ['https://www.topgear.com.ph', 'https://www.topgear.com', ],
	logo: '',
	display: 'Top Gear',
},
{
	brand: 'topspeed',
	url: ['https://www.topspeed.com', ],
	logo: 'https://www.topspeed.com/public/build/images/favicon-240x240.fd72c278.png',
	display: 'Top Speed',
},
{
	brand: 'travelandleisure',
	url: ['https://www.travelandleisure.com', ],
	logo: 'https://www.travelandleisure.com/apple-touch-icon-180x180.png',
	display: 'Travel + Leisure',
},
{
	brand: 'usatoday',
	url: ['https://www.usatoday.com', ],
	logo: 'https://www.usatoday.com/favicon.ico',
	display: 'USA TODAY',
},
{
	brand: 'usmagazine',
	url: ['https://www.usmagazine.com', ],
	logo: 'https://www.usmagazine.com/wp-content/uploads/2020/05/cropped-us-logo.png?w=192&quality=86&strip=all',
	display: 'Us Weekly',
},
{
	brand: 'usnews',
	url: ['https://www.usnews.com', ],
	logo: 'https://www.usnews.com/static-atlas/assets/img/social-logo-world-report.png',
	display: 'U.S. News',
},
{
	brand: 'vanityfair',
	url: ['https://www.vanityfair.com', ],
	logo: 'https://www.vanityfair.com/apple-touch-icon.png',
	display: 'Vanity Fair',
},
{
	brand: 'variety',
	url: ['https://variety.com', ],
	logo: 'https://variety.com/wp-content/uploads/2018/06/variety-favicon.png?w=128',
	display: 'Variety',
},
{
	brand: 'venturebeat',
	url: ['https://venturebeat.com', ],
	logo: 'https://venturebeat.com/wp-content/themes/vb-news/img/favicon.ico',
	display: 'VentureBeat',
},
{
	brand: 'vice',
	url: ['https://www.vice.com', 'https://link1.vice.com', ],
	logo: 'https://vice-web-statics-cdn.vice.com/favicons/vice/coast-228x228.png',
	display: 'VICE',
},
{
	brand: 'vogue',
	url: ['https://www.vogue.com', ],
	logo: 'https://www.vogue.com/apple-touch-icon.png',
	display: 'Vogue',
},
{
	brand: 'vox',
	url: ['https://www.vox.com', ],
	logo: 'https://cdn.vox-cdn.com/uploads/hub/sbnu_logo_minimal/441/touch_icon_iphone_retina_1000_yellow.755.png',
	display: 'Vox',
},
{
	brand: 'washingtonexaminer',
	url: ['https://www.washingtonexaminer.com', ],
	logo: 'https://wex-s3.s3.us-east-1.amazonaws.com/wp-content/uploads/2023/11/cropped-favicon-192x192.png',
	display: 'Washington Examiner',
},
{
	brand: 'washingtonindependent',
	url: ['https://washingtonindependent.com', ],
	logo: 'https://washingtonindependent.com/apple-touch-icon-1024x1024.png',
	display: 'The Washington Independent',
},
{
	brand: 'washingtonpost',
	url: ['https://www.washingtonpost.com', ],
	logo: 'https://www.washingtonpost.com/favicon.ico',
	display: 'Washington Post',
},
{
	brand: 'washingtontimes',
	url: ['https://www.washingtontimes.com', ],
	logo: 'https://www.washingtontimes.com/favicon.ico',
	display: 'Washington Times',
},
{
	brand: 'wcvb',
	url: ['https://www.wcvb.com', ],
	logo: '',
	display: 'WCVB Boston',
},
{
	brand: 'wealthofgeeks',
	url: ['https://wealthofgeeks.com', ],
	logo: 'https://wealthofgeeks.com/wp-content/uploads/2022/02/wealthofgeeks.png',
	display: 'Wealth of Geeks',
},
{
	brand: 'webmd',
	url: ['https://www.webmd.com', ],
	logo: 'https://www.webmd.com/favico/apple-touch-icon-114x114-precomposed.png',
	display: 'WebMD',
},
{
	brand: 'wired',
	url: ['https://www.wired.co.uk', 'https://www.wired.com', ],
	logo: 'https://www.wired.co.uk/apple-touch-icon.png',
	display: 'WIRED',
},
{
	brand: 'worldsoccertalk',
	url: ['https://worldsoccertalk.com', ],
	logo: 'https://statics.worldsoccertalk.com/favicon/apple-icon-180x180.png',
	display: 'World Soccer Talk',
},
{
	brand: 'wornandwound',
	url: ['https://wornandwound.com', ],
	logo: 'https://wornandwound.com/library/uploads/2019/01/cropped-site_Favicon_w_111519-1-192x192.jpg',
	display: 'Worn & Wound',
},
{
	brand: 'wrestlinginc',
	url: ['https://www.wrestlinginc.com', ],
	logo: 'https://www.wrestlinginc.com/img/wrestling-inc-favicon-448x448.png',
	display: 'Wrestling Inc.',
},
{
	brand: 'wwd',
	url: ['https://wwd.com', ],
	logo: 'https://wwd.com/wp-content/themes/vip/pmc-wwd-2021/assets/app/icons/favicon.png',
	display: 'WWD',
},
{
	brand: 'yahoo',
	url: ['https://news.yahoo.com', 'https://finance.yahoo.com', ],
	logo: 'https://news.yahoo.com/apple-touch-icon-precomposed.png',
	display: 'Yahoo!',
},
{
	brand: 'yardbarker',
	url: ['https://www.yardbarker.com', ],
	logo: 'https://www.yardbarker.com/apple-touch-icon.png',
	display: 'YARDBARKER',
},
{
	brand: 'zacks',
	url: ['https://www.zacks.com', ],
	logo: 'https://staticx.zacks.com/images/zacks/logos/logo_apple-touch-icon-144x144-precomposed.png',
	display: 'Zacks Investment Research',
},
{
	brand: 'zdnet',
	url: ['https://www.zdnet.com', ],
	logo: 'https://www.zdnet.com/a/fly/bundles/zdnetcore/images/logos/zdnet-logo-yellow.png',
	display: 'ZDNET',
},
{
	brand: 'zerohedge',
	url: ['https://www.zerohedge.com', ],
	logo: 'https://www.zerohedge.com/favicons/apple-touch-icon.png',
	display: 'Zerohedge',
},
{
	brand: 'zmescience',
	url: ['https://www.zmescience.com', ],
	logo: 'https://cdn.zmescience.com/wp-content/uploads/2021/08/cropped-logo-zmescience-192x192.png',
	display: 'ZME Science',
},
];
const LogoData = {LOGOS};
export default LogoData;
