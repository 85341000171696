import React, { useState } from "react";

import {
    Button, 
    TextField, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle
} from '@mui/material'

// EmailJS
import emailjs from '@emailjs/browser';

// Shared config & utils
import * as Constants from "../../config/Constants"
import * as Utils from "../utils/Utils";

function SubscribeDialog({ open, handleClose, showAlertMessage }) {
    const [validEmail, setValidEmail] = useState(false);
    const handleEmailValidation = (e) => {
        //test whether input is valid
        setValidEmail(Utils.validateEmail(e.target.value));
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                const info = {
                    from_email: formJson.email,
                    from_name: "Subscriber",
                    message: "There is new subscriber: " + formJson.email
                }

                emailjs
                    .send(Constants.EMAILJS_SERVICE_ID, Constants.EMAILJS_TEMPLATE_ID, info, {
                        publicKey: Constants.EMAILJS_PUBLIC_KEY,
                    })
                    .then(
                        () => {
                            showAlertMessage(Constants.SUBSCRIBE_SUCCESS_MSG);
                        },
                        (error) => showAlertMessage(Constants.SUBSCRIBE_FAILURE_MSG),
                    );
                handleClose();
            },
            }}
        >
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="tf_email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleEmailValidation(e)}
                    error={!validEmail}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={!validEmail}>Subscribe</Button>
            </DialogActions>
        </Dialog>
    );
}
export default SubscribeDialog;