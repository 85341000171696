const CATEGORIES=[
	'top stories',
	'business',
	'cars',
	'culture',
	'education',
	'entertainment',
	'game',
	'health',
	'law',
	'lifestyle',
	'politic',
	'science',
	'sport',
	'tech',
	'travel',
];
const CategoriesData={CATEGORIES};
export default CategoriesData;
