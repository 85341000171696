import React from "react";

import {
    Autocomplete,
    Checkbox,
    TextField
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import * as Constants from '../../config/Constants';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SimpleSearchCheckBox(props) {
    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={ props.data }
            disableCloseOnSelect
            defaultValue={ props.value }
            getOptionLabel={(option) => option}
            getOptionDisabled={(option) =>
                option === Constants.TOP_STORY_CATEGORY_NAME
            }
            sx={{
                maxWidth: Constants.SSDIALOG_LIST_MAX_WIDTH,
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    { option }
                </li>
            )}
            style={{ 
                flexGrow: 1,
                p: 2 
            }}
            renderInput={(params) => (
                <TextField {...params} label="Sources" placeholder="More" />
            )}
            onChange={(event, value) => {
                props.setValue(value);
			}}
        />
    );
}
