// API Server
export const API_IP_ADDRESS = 'https://api-server-l2bkm3n33q-wn.a.run.app/'
// export const API_IP_ADDRESS = 'http://localhost:8080/'
export const API_KEEP_OFFLINE_TIME = 1 // hours

// General
export const NEWSPAPER_BACKGROUND_COLOR = '#faf3ea'
export const TOP_STORY_CATEGORY_NAME = 'top stories'
export const TOP_STORY_MIN_SCORE = 9
export const NO_IMAGES_HOSTED_LOGOS = [
    'thefintechtimes',  // TODO: Can't access images hosted by The Fintech Times
]

// App.js
export const OUTERBOX_MIN_HEIGHT = '100vh'
export const ALERT_TIMEOUT = 3000
export const FAB_CATEGORY_LEFT = 5
export const FAB_CATEGORY_TOP = 300
export const FAB_GO_TOP_RIGHT = 16
export const FAB_GO_TOP_BOTTOM = 56
export const FAB_GO_TOP_SHOW_THRESH = 50
export const SNACKBAR_DURATION = 2000
export const SNACKBAR_HORIZONTAL = 'center'
export const SNACKBAR_VERTICAL = 'top'
export const VIEW_MODE_CATEGORY_NAME = 'category'
export const VIEW_MODE_SOURCE_NAME = 'source'

// Footer
export const FOOTER_PAD = 2
export const FOOTER_STACK_SPACING = 1

// Subscribe
export const SUBSCRIBE_SUCCESS_MSG = "Welcome aboard! You're now subscribed to our newsletter."
export const SUBSCRIBE_FAILURE_MSG = 'Oops, there was an issue with your subscription. Please try again later.'

// News.js
export const CIRCULAR_PROGRESS_SIZE = 24
export const TAG_FIXED_POS_TOP  = 70
export const TAG_FIXED_POS_LEFT = 10

// NewsItem.js
export const CARD_MAX_WIDTH = 365
export const CARD_BORDER_RARIUS = 4 //6
export const CARD_BOX_SHADOW = 4
export const CARD_BOX_SHADOW_SELECTED = 15
export const CARD_CONTENT_PADDING = 1
export const ACCORDION_BORDER = 0
export const ACCORDION_BORDER_SHADOW = 0
export const ACCORDION_BORDER_NO_SHADOW = 0
export const IMAGE_SIZE = 194
export const CARD_TITLE_FONT_SIZE = 17
export const CARD_TITLE_FONT_WEIGHT = 500
export const SECTION_FONT_SIZE = 16
export const ACCORDION_DETAIL_FONT_SIZE = 15
export const ACCORDION_TITLE_FONT_SIZE = 16
export const MEDAL_ICON_SIZE = 40
export const CARD_SOURCE_MAX_LENGTH = 35
export const CARD_TITLE_MAX_LENGTH = 115
export const CARD_SHARE_ICON_SIZE = 45
export const CARD_SHARE_ICON_PAD = 5
export const CARD_SHARE_TEXT = 'Check this out!'
export const CARD_LOGO_SIZE = 35
export const CARD_TAG_MAX_WIDTH = 250

// Sentiment
export const SENTIMENT_ICON_SIZE = 28
export const SENTIMENT_ICON_SHADOW = 0 //4
export const SENTIMENT_POPOVER_PAD = 2

// ClickableChip
export const MAX_NUM_TAGS_ALLOWED = 3
export const CHIP_FONT_SIZE = 10
export const DELETABLE_CHIP_FONT_SIZE = 14
export const TAG_SPACING = 1
export const TAG_SEPARATOR = ';'

// MissingSourceButton
export const MSB_LEFT = 10
export const MSB_RIGHT = 'auto'
export const MSB_TOP = 'auto'
export const MSB_BOTTOM = 56

// SideBarDrawer
export const SIDEBAR_DRAWER_WIDTH = 250
export const SIDEBAR_DRAWER_ANCHOR = 'right'

// ManageCategoriesDialog
export const MCDIALOG_LIST_MAX_WIDTH = 360
export const MCDIALOG_TITLE = 'Category Management'
export const MCDIALOG_CONTENT = 'Not interested in these categories:'

// SourceSelectionDialog
export const SSDIALOG_LIST_MAX_WIDTH = 360
export const SSDIALOG_TITLE = 'Source Management'
export const SSDIALOG_CONTENT = 'Stop receiving updates from these sources:'
export const BUTTON_SAVE_CHANGE = 'Save changes'

// SearchToolbar
export const SEARCH_TOOLBAR_HEADER_WIDTH = 250
export const SEARCH_TOOLBAR_BORDER_RADIUS = 10
export const SEARCH_TOOLBAR_HEIGHT = 46
export const SEARCH_TOOLBAR_AUTOCOMPLETE_WIDTH = '80%'
export const SEARCH_TOOLBAR_TOPIC_SOURCE = 'Sources'
export const SEARCH_TOOLBAR_TOPIC_TAG = 'Tags'

// CategoryBar
export const CATEGORY_BAR_INDICATOR_WIDTH = 40
export const CATEGORY_BAR_SELECTED_FONT_SIZE = 17
export const CATEGORY_BAR_FONT_SIZE = 14
export const CATEGORY_BAR_SELECTED_COLOR = '#fff'
export const CATEGORY_BAR_COLOR = 'rgba(255, 255, 255, 0.5)'
export const CATEGORY_BAR_HOVER_COLOR = 'rgba(255, 255, 255, 0.8)'

// EmailJS
export const EMAILJS_PUBLIC_KEY = 'kqI9Rkj45XdV_sYpn'
export const EMAILJS_SERVICE_ID = 'service_04lcup6'
export const EMAILJS_TEMPLATE_ID = 'template_qgv68kw'