import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Backdrop,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    IconButton,
    Popover,
    Typography,
} from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SentimentNeutralTwoToneIcon from '@mui/icons-material/SentimentNeutralTwoTone';
import SentimentSatisfiedAltTwoToneIcon from '@mui/icons-material/SentimentSatisfiedAltTwoTone';
import SentimentDissatisfiedTwoToneIcon from '@mui/icons-material/SentimentDissatisfiedTwoTone';
import SummarizeIcon from '@mui/icons-material/Summarize';

import * as Constants from '../../config/Constants';
import MultiLineText from "../UI/MultiLineText";
import AbbreviationData from "../../Data/Abbreviation";
import LogoData from "../../Data/LogoData";
import ShareButton from "../UI/ShareButton";
import * as Utils from "../utils/Utils";

const SentimentChip = props => {
    let { sentiment } = props
    switch (sentiment.toLowerCase()) {
        case "positive":
            return <Chip
                label="Sentiment"
                icon={<SentimentSatisfiedAltTwoToneIcon />}
                color='success'
                size="small"
            />
        case "negative":
            return <Chip
                label="Sentiment"
                icon={<SentimentDissatisfiedTwoToneIcon/>}
                // variant="outlined"
                color='error'
                size="small"
            />
        default:
            return <Chip
                label="Sentiment"
                icon={<SentimentNeutralTwoToneIcon/>}
                color='info'
                size="small"
            />
    }
};

const LogoImg = props => {
    let { news } = props;
    let logos = LogoData.LOGOS;
    let logo_img = '';

    for (var i = 0; i < logos.length; i++) {
        if (logos[i].brand === news.brand) {
            logo_img = logos[i].logo;
            break;
        }
    }

    if (logo_img.length === 0 && news.favicon.length > 0) {
        logo_img = news.favicon
    }

    if (Constants.NO_IMAGES_HOSTED_LOGOS.includes(news.brand))
        logo_img = '';

    if (logo_img.length > 0) {
        return <LazyLoadImage
            src={ logo_img }
            placeholderSrc={news.favicon}
            width='100%'
            height='100%'
            style={{
                objectFit: 'contain',
            }}
        />
    } else {
        return <div></div>
    }
}

const NewsItem = (props) => {
    let title = props.news.title
    if (title.length > Constants.CARD_TITLE_MAX_LENGTH) {
        title = Utils.cutLongText(title, Constants.CARD_TITLE_MAX_LENGTH);
    }

    let logos = LogoData.LOGOS;

    let abbs = AbbreviationData.ABBREVIATIONS;

    const getDisplayNameForURL = (url) => {
        var domain = Utils.get_domain_from_url(url).replace('www.', '');
        for (var i = 0; i < logos.length; i++) {
            for (var j = 0; j < logos[i].url.length; j ++)
                if (logos[i].url[j].includes(domain))
                    return logos[i].display;
        }
        return null;
    };

    let has_image = (props.news.top_img !== null && props.news.top_img.length > 0);
    if (Constants.NO_IMAGES_HOSTED_LOGOS.includes(props.news.brand))
        has_image = false;

    const processSourceName = (value) => {
        if (value === null)
            return '';
        return Utils.cutLongText(value, Constants.CARD_SOURCE_MAX_LENGTH);
    };

    let tags = props.news.tags.replaceAll(';', ', ');
    const processTag = (tags) => {
        let final_tags = tags;
        for (var i = 0; i < abbs.length; i++)
            final_tags = final_tags.replaceAll(abbs[i].word, abbs[i].abb);
        return final_tags;
    }

    let published_at = Utils.getTimeAgo(
        new Date(props.news.published_at)
    );

    // popover info
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = Boolean(anchorEl);
    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return(
        <div>
            <Card sx={{
                maxWidth: Constants.CARD_MAX_WIDTH,
                borderRadius: Constants.CARD_BORDER_RARIUS,
                boxShadow: Constants.CARD_BOX_SHADOW,
                ':hover': {
                    boxShadow: Constants.CARD_BOX_SHADOW_SELECTED },
                    // backgroundColor: Constants.NEWSPAPER_BACKGROUND_COLOR
                }}
            >
                <CardHeader
                    avatar={
                        <IconButton
                            onClick={() => {
                                Utils.openInNewTab(props.news.source_url);
                            }}
                            sx={{ 
                                width: Constants.CARD_LOGO_SIZE,
                                height: Constants.CARD_LOGO_SIZE,
                                p: 0,
                            }}
                        >
                            <LogoImg news={ props.news } />
                        </IconButton>
                    }
                    action={
                        <ShareButton news={ props.news } />
                    }
                    title={ 
                        <b>{processSourceName(getDisplayNameForURL(props.news.source_url))}</b>
                    }
                    subheader={ published_at }
                    sx={{ 
                        p:Constants.CARD_CONTENT_PADDING,
                    }}
                />
                <CardActionArea href={props.news.url} target='_blank'>
                    { has_image &&
                        <LazyLoadImage
                            alt='thumbnail'
                            src={ props.news.top_img }
                            height={ Constants.IMAGE_SIZE }
                            width='100%'
                            style={{
                                objectFit: 'contain',
                            }}
                        />
                    }
                    <CardContent sx={{ p: Constants.CARD_CONTENT_PADDING }}>
                        <Typography
                            variant="subtitle1"
                            fontSize={ Constants.CARD_TITLE_FONT_SIZE }
                            fontWeight={ Constants.CARD_TITLE_FONT_WEIGHT }
                        >
                            { title }
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardContent sx={{ 
                    p: Constants.CARD_CONTENT_PADDING,
                    display: 'flex',
                    justifyContent: 'right' 
                    }}
                >
                    { (props.news.tags.length > 0) &&
                        <Chip 
                            label={processTag(tags)}
                            icon={<LocalOfferIcon/>}
                            variant="outlined"
                            color='info'
                            size="small"
                            sx={{ mr: 0.5, maxWidth: Constants.CARD_TAG_MAX_WIDTH }}
                            onClick={(event) => handlePopoverClick(event)}
                        />
                    }
                    <SentimentChip sentiment={props.news.sentiment_analysis} />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={openPopover}
                    >
                        <Popover
                            // id={id}
                            open={ openPopover }
                            anchorEl={ anchorEl }
                            onClose={ handlePopoverClose }
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{ p: 1 }}>{tags}</Typography>
                        </Popover>
                    </Backdrop>
                </CardContent>
                <Divider />
                <CardContent sx={{ p: 0 }}>
                    <Accordion sx={{
                        border: Constants.ACCORDION_BORDER,
                        boxShadow: Constants.ACCORDION_BORDER_SHADOW,
                        fontSize: Constants.SECTION_FONT_SIZE,
                        // backgroundColor: Constants.NEWSPAPER_BACKGROUND_COLOR,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                fontSize: Constants.ACCORDION_TITLE_FONT_SIZE,
                            }}
                        >
                            <Chip
                                label="Summary"
                                color="primary"
                                variant="outlined"
                                size="large"
                                icon={<SummarizeIcon />}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MultiLineText
                                text={props.news.summary}
                                sx={{ fontSize: Constants.ACCORDION_DETAIL_FONT_SIZE }}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ 
                        border: Constants.ACCORDION_BORDER,
                        boxShadow: Constants.ACCORDION_BORDER_SHADOW,
                        fontSize: Constants.SECTION_FONT_SIZE,
                        // backgroundColor: Constants.NEWSPAPER_BACKGROUND_COLOR
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            sx={{ py: 0 }}
                        >
                            <Chip
                                label="Analysis"
                                color="primary"
                                variant="outlined"
                                size="large"
                                icon={<AnalyticsIcon />}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MultiLineText
                                text={props.news.impact} 
                                sx={{ fontSize: Constants.ACCORDION_DETAIL_FONT_SIZE }}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ 
                        border: Constants.ACCORDION_BORDER,
                        boxShadow: Constants.ACCORDION_BORDER_NO_SHADOW,
                        fontSize: Constants.SECTION_FONT_SIZE,
                        // backgroundColor: Constants.NEWSPAPER_BACKGROUND_COLOR
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Chip
                                label="Glossary"
                                color="primary"
                                variant="outlined"
                                size="large"
                                icon={<ListIcon />}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MultiLineText
                                text={props.news.glossary} 
                                sx={{ fontSize: Constants.ACCORDION_DETAIL_FONT_SIZE }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
            </Card>
        </div>
    );
};
export default NewsItem;